import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { UsermodalComponent } from '../../layouts/usermodal/usermodal.component'
import {ConfirmationDialogComponent} from '../../layouts/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';


declare var $:any;
export interface User{
  timedate:Date,
  startime:Time,
  endtime:Time,
  projname:String,
  taskname:String,
  description:string,
}
interface Role {
  value: string;
  id: string;
}

interface Designation {
  value: string;
  id: string;
}

const ELEMENT_DATA:User[] = [];
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit , AfterContentChecked {
  displayedColumns:string[] = ['id','name','email','date','fulladdress','tags','upload','action'];
  data:any;
  adduserbtn:any;
  edituserbtn:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  userid:any;
  roleoptions: Role[] = [
    {value: 'User', id: '1'},
    {value: 'Manager', id: '2'},
  ];
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  desOptions: Role[] = [
    {value: 'Android developer', id: '1'},
    {value: 'iOS Developer', id: '2'},
    {value: 'Web developer', id: '3'},
    {value: 'Designer', id: '4'},
    {value: 'Python Developer', id: '5'}
    ];
    addUserForm = new FormGroup({
    user_id: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required]),
    gender: new FormControl('',[Validators.required]),
    birthdate: new FormControl('',[Validators.required]),
    role: new FormControl('',[Validators.required]),
    designation: new FormControl('',[Validators.required]),
    contactno: new FormControl('',[Validators.required]),
    alternatecontact: new FormControl('',[Validators.required]),
    parentscontact: new FormControl('',[Validators.required]),
    currentaddress: new FormControl('',[Validators.required]),
    fulladdress: new FormControl('',[Validators.required]),
    joindate: new FormControl('',[Validators.required]),
    upload: new FormControl(''),
    leaving_date: new FormControl(''),
  });

  file_Array:any;
  constructor(public dialogbox: MatDialog,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.adduserbtn = true;
    this.UserList(this.searchvalue,this.pageSize,this.currentPage);
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onFileSelected(event:any) {
    console.log(event.target.files);
    if (event.target.files.length > 0) 
    {
      const file = event.target.files[0];
      this.file_Array = event.target.files[0];
    }
   
  }
  UserList(searchvalue:any,pageSize:any,currentPage:any) {
    this.http.post(Api.url.adminUser,{searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
      // console.log(response);
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }
   changePaging(event: PageEvent){
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.UserList(this.searchvalue,this.pageSize,page);
   }
   adduser(){
    const pipe = new DatePipe('en-US');
    const now = Date.now();
      
    const join_date = pipe.transform(this.addUserForm.get('joindate').value, 'yyyy-MM-dd');
    const leave_date =  pipe.transform(this.addUserForm.get('leaving_date').value, 'yyyy-MM-dd');
    const birth_date =  pipe.transform(this.addUserForm.get('birthdate').value, 'yyyy-MM-dd');

    const formData = new FormData();
    if(this.file_Array){
      formData.append('upload', this.file_Array);
    }
    formData.append('name', this.addUserForm.get('name').value);
    formData.append('email', this.addUserForm.get('email').value);
    formData.append('password', this.addUserForm.get('password').value);
    formData.append('gender', this.addUserForm.get('gender').value);
    formData.append('birthdate',birth_date);
    formData.append('role', this.addUserForm.get('role').value);
    formData.append('designation', this.addUserForm.get('designation').value);
    formData.append('contactno', this.addUserForm.get('contactno').value);
    formData.append('alternatecontact', this.addUserForm.get('alternatecontact').value);
    formData.append('parentscontact', this.addUserForm.get('parentscontact').value);
    formData.append('currentaddress', this.addUserForm.get('currentaddress').value);
    formData.append('fulladdress', this.addUserForm.get('fulladdress').value);
    formData.append('joindate',join_date);
    formData.append('leaving_date', leave_date);

    this.http.post(Api.url.adminaddUser,formData).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add User Successfully","","green-snackbar");
         $("#userModal").modal('hide');
         this.UserList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

   editUser(element:any){
    $("#userModal").modal('show');
    this.adduserbtn = false;
    this.edituserbtn = true;
    let index = this.desOptions.findIndex(function(el: { value: string; }) {
      return el.value == element.designation;
    });
    // let index = this.desOptions.findIndex(x => x.value === element.designation);
    console.log(index);
          this.addUserForm.controls['user_id'].setValue(element['id']);
          this.addUserForm.controls['name'].setValue(element['name']);
          this.addUserForm.controls['email'].setValue(element['email']);
          this.addUserForm.controls['password'].setValue(element['password']);
          this.addUserForm.controls['gender'].setValue(element['gender']);
          let Array =  element['birthdate'].split("-");
          const date = new Date(Array[2], Array[1], Array[0]);
          this.addUserForm.controls['birthdate'].setValue(date);
          this.addUserForm.controls['role'].setValue(element['role']);
          // console.log(this.data.data);
          this.addUserForm.controls['designation'].setValue(this.desOptions[index]['id']);
          this.addUserForm.controls['contactno'].setValue(element['contactno']);
          this.addUserForm.controls['alternatecontact'].setValue(element['alternatecontact']);
          this.addUserForm.controls['parentscontact'].setValue(element['parentscontact']);
          this.addUserForm.controls['currentaddress'].setValue(element['currentaddress']);
          this.addUserForm.controls['fulladdress'].setValue(element['fulladdress']);
          let join_Array =  element['joindate'].split("-");
          const joindate = new Date(join_Array[2], join_Array[1], join_Array[0]);
          this.addUserForm.controls['joindate'].setValue(joindate);
          this.addUserForm.controls['upload'].setValue(element['upload']);
          if(element['leavedate'] != null)
          {
            let leave_Array =  element['leavedate'].split("-");
            const leavedate = new Date(leave_Array[2], leave_Array[1], leave_Array[0]);
            this.addUserForm.controls['leaving_date'].setValue(leavedate);
          }

   }
   edituser()
   {
    
     this.http.post(Api.url.admineditUser, this.addUserForm.value).then((response: any) => {
       if (response.success) {
          this.openSnackBar("Edit User Successfully","","green-snackbar");
          $("#userModal").modal('hide');
          $('#myform')[0].reset();
          this.UserList(this.searchvalue,this.pageSize,this.currentPage);
          this.edituserbtn = false;

       }
     });
   
   }
  
   SearchUser(event:any)
   {
    this.searchvalue = event.target.value;
    this.UserList(this.searchvalue,this.pageSize,this.currentPage);
   }

   deleteUser(id:any)
   {
    this.userid = id;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        id:this.userid,
        model:"user",
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Delete',
          cancel: 'No'
        }
      }
    });
   }
}
