import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked,NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Resume{
  name:string,
  department:string,
  contact_no:Number,
  address:String,
  experince:String,
  iscontacted:string,
  dateofcontact:Date
}

interface Role {
  value: string;
  id: string;
}
const ELEMENT_DATA:Resume[] = [];
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  displayedColumns:string[] = ['id','name','department','contact_no','address','experience','iscontacted','dateofcontact','action'];
  data:any;
  addresumebtn:any;
  editresumebtn:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Resume> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  resumeid:any;
  addResumeForm = new FormGroup({
    resumeid: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    department:new FormControl('',[Validators.required]),
    phone:new FormControl('',[Validators.required]),
    address:new FormControl('',[Validators.required]),
    experience:new FormControl('',[Validators.required]),
    iscontacted:new FormControl(''),
    dateofcontact:new FormControl('')
  });
  contact_contition:any;
  title_section:string;

  desOptions: Role[] = [
    {value: 'Android Developer', id: '1'},
    {value: 'iOS Developer', id: '2'},
    {value: 'Web Developer', id: '3'},
    {value: 'Designer', id: '4'},
    {value: 'Python Developer', id: '5'}
    ];

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  
  constructor(private NgZone: NgZone,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void 
  {
    this.addResumeForm.reset();
    this.title_section = "Add Resume";
    this.contact_contition = false;
    this.addresumebtn = true;
    this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
  }
  
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onSelectEvent(value: any){
    // console.log(value);
    if(value == "Yes"){
      this.contact_contition = true;
    }else{
      this.contact_contition = false;
    }
  }
  ResumeList(searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminResumeList,{searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
      // console.log(response.data.total);
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   addResume(){
    this.http.post(Api.url.adminResumeAdd, this.addResumeForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Resume Successfully","","green-snackbar");
         $("#ResumeModal").modal('hide');
         this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

   editResume(element:any)
   {
      this.addresumebtn = false;
      this.editresumebtn = true;
      $("#ResumeModal").modal('show');
      this.title_section = "Update Resume";

      let index = this.desOptions.findIndex(x => x.value === element.department);
      
      this.addResumeForm.controls['resumeid'].setValue(element['id']);
      this.addResumeForm.controls['name'].setValue(element['name']);
      this.addResumeForm.controls['department'].patchValue(this.desOptions[index]['id']);
      this.addResumeForm.controls['phone'].setValue(element['phone']);
      this.addResumeForm.controls['address'].setValue(element['address']);
      this.addResumeForm.controls['experience'].setValue(element['experience']);
      this.addResumeForm.controls['iscontacted'].setValue(element['iscontacted']);
      if(element['iscontacted'] == "Yes"){
        this.contact_contition = true;
        let join_Array =  element['dateofcontact'].split("-");
        const contactdate = new Date(join_Array[2], join_Array[1], join_Array[0]);
        this.addResumeForm.controls['dateofcontact'].setValue(contactdate);
      }
   }

   updateResume()
   {
    this.http.post(Api.url.adminUpdateResume, this.addResumeForm.value).then((response: any) => {
      if (response.success) 
      {
         this.openSnackBar("Edit Resume Successfully","","green-snackbar");
         $("#ResumeModal").modal('hide');
         this.addResumeForm.reset();
         this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
         this.editresumebtn = false;
         this.addresumebtn = true;
         this.title_section = "Add Resume";
      }
    });

   }

   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    // console.log(this.currentPage+1);
    this.ResumeList(this.searchvalue,this.pageSize,page);
   }

   SearchResume(event:any)
   {
    this.searchvalue = event.target.value;
    this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
   }

   deleteResume(id:any){
    // console.log(id);
    this.resumeid = id;
    $("#delresume").modal('show');

   }

   deleteresume()
   {
    this.http.post(Api.url.adminResumeDelete,{resumeid:this.resumeid}).then((response: any)=>{
      $("#delresume").modal('hide');
      this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
      this.openSnackBar("Delete Resume Successfully","","green-snackbar");
    });

   }
  

}
