
<div class="mat-elevation-z8">
  <div class="row g-3 section">
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <div class="dropdown">
        <div class="input-group">
            <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                <option selected value="0">Select Username</option>
                <ng-container *ngFor="let des of usersname">
                    <option value="{{des.id}}">{{des.name}}</option>
                </ng-container>
            </select>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex.gt-sm="100%" class="width-100">
      <!-- <div id="attendancecalendar"> </div> -->

        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list cols="1" >
                    <mat-grid-tile>
                        <div id="attendancecalendar"> </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
        <div class="mat-elevation-z8 table-section">
          <h4>Timesheet</h4>
          <table mat-table [dataSource]="dataSource"  matSort>
                              <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
                                <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
                              </ng-container>
                          
                              <ng-container matColumnDef="startime">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header> startime </th>
                                <td mat-cell *matCellDef="let element"> {{element.startime}} </td>
                              </ng-container>
                          
                              <ng-container matColumnDef="endtime">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header> endtime </th>
                                <td mat-cell *matCellDef="let element"> {{element.endtime}} </td>
                              </ng-container>
                          
                              <ng-container matColumnDef="projname">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header> projname </th>
                                <td mat-cell *matCellDef="let element"> {{element.projname}}</td>
                              </ng-container>
                          
                              <ng-container matColumnDef="taskname">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header>  taskname  </th>
                                <td mat-cell *matCellDef="let element"> {{element.taskname}}</td>
                              </ng-container>
                          
                              <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef  mat-sort-header>  description  </th>
                                <td mat-cell *matCellDef="let element">  {{element.description}} </td>
                              </ng-container>
                          
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="paginate-section">
            <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
            </mat-paginator>
        </div>
          <br/>
          <div class="mat-elevation-z8 table-section">
            <h4>Leave</h4>
          <table mat-table [dataSource]="dataSource2"  matSort>
              <ng-container matColumnDef="username">
                  <th mat-header-cell *matHeaderCellDef  mat-sort-header> UserName </th>
                  <td mat-cell *matCellDef="let element"> {{element.username}} </td>
              </ng-container>
              <ng-container matColumnDef="leavedate">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Leave Date </th>
                <td mat-cell *matCellDef="let element"> {{element.leavedate}} </td>
              </ng-container>
              <ng-container matColumnDef="leavereason">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Leave Reason </th>
                <td mat-cell *matCellDef="let element"> {{element.leavereason}} </td>
              </ng-container>
              <ng-container matColumnDef="leavetype">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Leave Type </th>
                <td mat-cell *matCellDef="let element"> {{element.leavetype}} </td>
              </ng-container>
              <ng-container matColumnDef="admin_note">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> Admin Note </th>
                <td mat-cell *matCellDef="let element"> {{element.admin_note}} </td>
              </ng-container>
              <ng-container matColumnDef="isapprove">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> IsApprove </th>
                <td mat-cell *matCellDef="let element"> {{element.isapprove}} </td>
              </ng-container>
                          
                  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table>
          <!-- <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
          </mat-paginator> -->
        </div>
    </div>
  </div>
</div>

