import { Component,OnInit, ChangeDetectorRef, AfterContentChecked, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MenuItems } from '../../../shared/menu-items/menu-items';


declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  checked = true;
  disabled = false;
  loginUsername:any;
  username: any;
  role:any;
  
  changeThemeForm = new FormGroup({
    theme_color: new FormControl(''),
    back_color:new FormControl(''),
    menu_color:new FormControl('')
  });
  
  Setting_Menu = new FormGroup({
    Resumes: new FormControl(false),
    Timesheet:new FormControl(false),
    Attendance:new FormControl(false),
    Machine: new FormControl(false),
    Tasklogs:new FormControl(false),
    Inventory:new FormControl(false),
    Document:new FormControl(false),
  });
  menu:any;
  isSlideChecked: true;
  menus=[];
  menuList: any;
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(public menuItems: MenuItems,private router: Router,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api){
  }
  logout():void{
    localStorage.removeItem('logindata');
    window.location.href = '/login';
  }
  profile(pageName:any):void{
    this.router.navigate([`${pageName}`]);
  }
  ngOnInit() 
  {
    
    // getComputedStyle(document.documentElement)['--themecolor-alt'] = 'red';
    // $themecolor-alt:blue;
    this.MenuList();
    this.AdminDetail();
  }
  ngAfterViewInit(){
    
  }
  ngAfterContentChecked(): void {
    

    this.loginUsername = localStorage.getItem('logindata');
 
    if(localStorage.getItem('ROLE') == "Admin"){
      this.role = true;
    }else{
      this.role = false;
    }
    let array = JSON.parse(this.loginUsername);
    this.username = array['user']['name'];
    this.changeDetector.detectChanges();
   
  }
  DefaultTheme(){
    this.http.post(Api.url.adminThemeChange,{theme_color:'#1e88e5',back_color:'#26c6da',menu_color:'#ffffff'}).then((response: any) => {
      if (response.success) 
      {
        // console.log(response.data.theme_color);
        $(".mat-toolbar.mat-primary").css('background',response.data.theme_color);
        // localStorage.setItem("theme_color",response.data.theme_color);
         this.openSnackBar("Change Theme Successfully","","green-snackbar");
         $("#addsettingmodal").modal('hide');
         var r:any = document.querySelector(':root');
         r.style.setProperty('--themecolor-alt', response.data['back_color']);
         r.style.setProperty('--menu-color', response.data['menu_color']);
      }
    });
  }

  changeTheme()
  {
    this.http.post(Api.url.adminThemeChange, this.changeThemeForm.value).then((response: any) => {
      if (response.success) {
        // console.log(response.data.theme_color);
        $(".mat-toolbar.mat-primary").css('background',response.data.theme_color);
        // localStorage.setItem("theme_color",response.data.theme_color);
         this.openSnackBar("Change Theme Successfully","","green-snackbar");
         $("#addsettingmodal").modal('hide');
         var r:any = document.querySelector(':root');
         r.style.setProperty('--themecolor-alt', response.data['back_color']);
         r.style.setProperty('--menu-color', response.data['menu_color']);
      }
    });
  }

  changed(e)
  {
    // if(e.checked  == true){
    this.menus.push(e.source.name);
  }

  AdminDetail()
  {
    this.http.get(Api.url.adminDetail).then((response: any) => {
      if (response.success) {
        // console.log(response.data);
        $(".mat-toolbar.mat-primary").css('background',response.data['theme_color']);
        var r:any = document.querySelector(':root');
        r.style.setProperty('--themecolor-alt', response.data['back_color']);
        r.style.setProperty('--menu-color', response.data['menu_color']);
        
        this.changeThemeForm.controls['theme_color'].setValue(response.data['theme_color']);
        this.changeThemeForm.controls['back_color'].setValue(response.data['back_color']);
        this.changeThemeForm.controls['menu_color'].setValue(response.data['menu_color']);
      }
    }); 
  }

  settingMenu(){
    // console.log(this.Setting_Menu.value);
    this.http.post(Api.url.adminManagemenu,{menu:this.menus}).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Menu Update Successfully","","green-snackbar");
         $("#menu_setting").modal('hide'); 
         this.MenuList();
         this.menus = [];
      }
    }); 
  }
 
  MenuList(){
    this.http.post(Api.url.adminMenuList).then((response: any) => {
      if (response.success)
       {
        for(var i = 0 ;i<response.data.length;i++)
        {
          this.Setting_Menu.controls[response.data[i]['menu_name']].setValue(true);
          this.menuList = response.data[i]['menu_name'];
          const index = this.menuItems.getMenuitem().findIndex(x => x.name === this.menuList);
          this.menuItems.getMenuitem().splice(index, 1);
        }
      }
    }); 
  }
}

