import { Component, AfterViewInit, EventEmitter, ViewChild, ElementRef, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-selectbox',
	templateUrl: './selectbox.component.html',
	styleUrls: []
})
export class selectboxComponent implements OnInit {
	@Input() items = [];
	@Input() name = '';
	@Input() group = null;
	@Output() newItemEvent = new EventEmitter<string>();
	searchControl = new FormControl();
	originalItems: any[] = [];
	@ViewChild('inputField') inputField: ElementRef;

	placeholderText = 'Search Projects';
	label: string = 'Search Projects';

	addNewItem(event: any) {
		// console.log("Hiiii  NEW CHANGE---->", event);

		this.newItemEvent.emit(event.option.viewValue);
		// this.newItemEvent.emit(event.option.id);

	}
	filteredItems: any[] = [];
	searchTerm: string = '';

	ngOnInit(): void {

	}
	searchProject(event: any) {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterItems();
		console.log("Hii");

	}

	filterItems() {
		setTimeout(() => {

			this.filteredItems = this.searchTerm ? this.items.filter(item => item.value.toLowerCase().includes(this.searchTerm)) : this.items

		}, 250);
	}

	updatePlaceholder() {

		if (this.name !== 'projectname') {

			this.label = 'Search Tasks';
			this.placeholderText = 'Search Task';

		}
		else {

			this.label = 'Search Projects';
			this.placeholderText = 'Search Projects';

		}
	}

	ngOnChanges(changes: SimpleChanges): void {

		if (this.inputField && this.inputField.nativeElement) {
			this.inputField.nativeElement.value = '';
		}
		if (changes.name && changes.name.currentValue) {

			this.updatePlaceholder();
		}
	}



}
