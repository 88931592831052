
<div class="mat-elevation-z8">
  <div class="row g-3 section">
    <div class="col-sm-4">
      <div class="dropdown">
        <div class="input-group">
            <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                <option selected value="0">Select Project</option>
                <ng-container *ngFor="let des of projectname">
                    <option value="{{des.name}}">{{des.name}}</option>
                </ng-container>
            </select>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mobile-none"></div>
   
    <div class="col-sm-4">
      <button  mat-raised-button color="primary" class="user-btn width-100 project-btn" data-bs-toggle="modal" data-bs-target="#ProjectModal">Add Project</button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="startdate">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Startdate </th>
          <td mat-cell *matCellDef="let element"> {{element.startdate}} </td>
        </ng-container>

        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Users </th>
          <td mat-cell *matCellDef="let element"> {{element.username}}</td>
        </ng-container>

        <ng-container matColumnDef="finished">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Finished </th>
            <td mat-cell *matCellDef="let element"> {{element.isfinished}}</td>
        </ng-container>
      
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="example-icon edit-icon" (click)="editProject(element)">edit</mat-icon>
                <mat-icon class="example-icon delete-icon" (click)="deleteProject(element.id)">delete</mat-icon>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
    </mat-paginator>
 
</div>
<div class="modal hide" id="ProjectModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">{{title_section}}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <form [formGroup]="addProjectForm" name="addproject" style="margin-top:-45px;" id="myform">
                <input matInput value="" placeholder="Enter id" #message formControlName="project_id" name="project_id" style="visibility:hidden;" >
                <mat-form-field>
                  <mat-label>Project Name </mat-label>
                  <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
                </mat-form-field>
            
                <span class="text-danger"
                   *ngIf="(addProjectForm.get('name')?.invalid && addProjectForm.get('name')?.touched && addProjectForm.get('name')?.errors?.required )">
                       ProjectName is required
                </span>

                <mat-form-field>
                    <mat-label> Description </mat-label>
                    <textarea matInput value="" placeholder="Enter Description" #message formControlName="description" name="description"></textarea>
                </mat-form-field>
              
                  <span class="text-danger"
                     *ngIf="(addProjectForm.get('description')?.invalid && addProjectForm.get('description')?.touched && addProjectForm.get('description')?.errors?.required )">
                        Description is required
                  </span>

                  <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
                    <mat-label>Choose a startDate</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="startdate" name="startdate">
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                  <span class="text-danger"
                  *ngIf="(addProjectForm.get('startdate')?.invalid && addProjectForm.get('startdate')?.touched && addProjectForm.get('startdate')?.errors?.required )">
                    Startdate is required
                  </span> 

                  <mat-form-field appearance="fill">
                    <mat-label>Select User </mat-label>
                    <mat-select formControlName="users" name="users">
                      <mat-option *ngFor="let des of usersname" [value]="des.id">
                        {{des.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                 
                  <span class="text-danger"
                    *ngIf="(addProjectForm.get('users')?.invalid && addProjectForm.get('users')?.touched && addProjectForm.get('users')?.errors?.required )">
                        Users is required
                  </span>
                  
                  <mat-form-field appearance="fill">
                    <mat-label>Is Finished </mat-label>
                    <mat-select formControlName="finished">
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <span class="text-danger"
                  *ngIf="(addProjectForm.get('finished')?.invalid && addProjectForm.get('finished')?.touched && addProjectForm.get('finished')?.errors?.required )">
                    Finished is required
                </span>
                  
                <div mat-dialog-actions style="float: right;">
                    <button type="submit" *ngIf="addprojectbtn" [disabled]="addProjectForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addProject()">Save</button>
                    <span class="margin-right-20"></span>
                    <button type="submit" *ngIf="editprojectbtn" mat-raised-button color="primary" tabindex="2" (click)="saveProject()">Update</button>
                    <span class="margin-right-20"></span>

                    <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
                </div>
            </form>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="delproject" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Project</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="deleteproj()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
</div>