<mat-card>
    <div class="p-20 bg-dark position-relative">
        <h4 class="card-title  text-white m-0">Recent Activity
        </h4>

        <h6 class="card-subtitle text-white m-0 op-5"></h6>
        <!-- <button mat-mini-fab color="accent" class="add-data">+</button> -->
    </div>

    <div class="message-box  Contact-box p-20">
        <div class="message-widget contact-widget">
            <a *ngFor="let data of recentActivityData">
                <div class="mail-contnet-left margin-auto">
                    <!-- <img [src]='data.image' alt="user" class="img-circle"> -->
                    <mat-chip-list>
                        <mat-chip color="primary" selected="true">{{data.type}}</mat-chip>
                    </mat-chip-list>


                    <!-- <h5> {{data.type}} </h5> -->
                    <span class="profile-status pull-right"></span>
                </div>
                <!-- <div [ngStyle]="{'margin-right':'20px', 'margin-top':'-2px' }">
                    <mat-nav-list appAccordion>
                        <mat-icon [ngStyle]="{'color':'#FC4B6C','font-size':'18px' }" class="material-icons-outlined">
                            check_circle
                        </mat-icon>

                    </mat-nav-list>
                </div> -->
                <div class="mail-contnet recent-margin">
                    <h5 [ngStyle]="{'font-size':'14px','font-weight':'250'}"> {{data.name }}
                    </h5>
                    <h5 *ngIf="data.type==='CheckIn'" [ngStyle]="{'font-size':'14px','font-weight':'250'}">
                        {{data.createtime}} {{data.createdate}} {{data.description}}
                    </h5>
                    <h5 *ngIf="data.type==='CheckOut'" [ngStyle]="{'font-size':'14px','font-weight':'250'}">
                        {{data.createdate}} {{data.taskname}} {{data.leavedate}} {{data.checkout}} {{data.description}}
                    </h5>
                    <h5 *ngIf="data.type==='Task'" [ngStyle]="{'font-size':'14px','font-weight':'250'}">
                        {{data.taskname}} {{data.description}} {{data.endtime}}
                    </h5>

                    <h5 *ngIf="data.type==='Leave'" [ngStyle]="{'font-size':'14px','font-weight':'250'}">
                        {{data.leavedate}}
                    </h5>
                </div>

            </a>
        </div>
    </div>
</mat-card>