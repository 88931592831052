import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';
import {AdminReportCalendar} from '../../shared/calendars/adminattendancereport'


declare var $:any;
export interface Timesheet{
  startime:String,
  endtime:String,
  projname:String,
  taskname:String,
  description:String
}
export interface employeeData {
  username: string;
  leavedate: string;
  leavereason: string;
  leavetype: string;
  admin_note: string;
  isapprove: string;
}
@Component({
  selector: 'app-adminreport',
  templateUrl: './adminreport.component.html',
  styleUrls: ['./adminreport.component.css']
})
export class AdminreportComponent implements OnInit {
  usersname: any;
  id:any;
  displayedColumns:string[] = ['id','startime','endtime','projname','taskname','description'];
  displayedColumns2: string[] = ['username', 'leavedate','leavereason', 'leavetype', 'admin_note', 'isapprove'];
  data:any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Timesheet> = new MatTableDataSource();
  dataSource2: MatTableDataSource<employeeData> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  Month:any;

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) 
  { 

  }
  ngAfterViewInit() {
    this.id = 15;
		AdminReportCalendar().init(this);
	 }
   getData(start:any, end:any, self:any){
		return new Promise(resolve => {
			this.http.post(Api.url.attendanceByUserId,{start: start.unix(),end: end.unix(),userid: this.id})
			.then((doc: any)=>{
			  resolve(doc);
			});
		  })
	 }
  ngOnInit(): void {
    this.UserList();
  }
  UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }

   TimesheetList(Month:any,id:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.timesheetReport,{month:Month,id:id,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    });
   }

   private getLeaveList(Month:any,id:any,pageSize:any,currentPage:any) 
   {
    this.http.post(Api.url.leaveReport,{month:Month,id:id,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
      // console.log(response);
      if(response.success)
      {
       this.dataSource2.data = response.data;
      //  this.paginator.length = response.data.total;
      //  this.length = response.data.total;
      }
     });
  }
   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.TimesheetList(this.Month,this.id,this.pageSize,page);
   }
   onSelectEvent(event:any)
   {
    const date = $('#attendancecalendar').fullCalendar('getCalendar');
    const monthInt = new Date(date.view.title);
    this.Month = monthInt.getMonth()+1;

    AdminReportCalendar().init(this);
    this.id = event.target.value;
    const start = "";
    const end = "";
    this.getData(start,end,self);
    this.TimesheetList(this.Month,this.id,this.pageSize,this.currentPage);
    this.getLeaveList(this.Month,this.id,this.pageSize,this.currentPage);
   }

}
