import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Inventory{
  name:string,
  description:string,
  given_to_user:Number,
  date_inventory:String,
  status:String,
}

@Component({
  selector: 'app-admininventory',
  templateUrl: './admininventory.component.html',
  styleUrls: ['./admininventory.component.css']
})
export class AdmininventoryComponent implements OnInit {
  displayedColumns:string[] = ['id','name','description','giventotheuser','date_inventory','status','action'];
  data:any;
  addinventorybtn:any;
  editinventorybtn:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Inventory> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  inventory_id:any;
  addInventoryForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    description:new FormControl('',[Validators.required]),
    giventotheuser:new FormControl('',[Validators.required]),
    date_inventory:new FormControl('',[Validators.required]),
    status:new FormControl('',[Validators.required]),
  });
  contact_contition:any;
  title_section:string;
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void 
  {
    this.title_section = "Add Inventory"
    this.addinventorybtn = true;
    this.editinventorybtn = false; 
    this.InventoryList(this.searchvalue,this.pageSize,this.currentPage);

  }
   InventoryList(searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.admininventoryList,{searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success){
      // console.log(response.data.total);
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.InventoryList(this.searchvalue,this.pageSize,page);
   }
   addResume(){
    this.http.post(Api.url.admininventoryAdd,this.addInventoryForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Inventory Successfully","","green-snackbar");
         $("#InventoryModal").modal('hide');
         this.InventoryList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }
   editInventory(element:any)
   {
      this.title_section = "Update Inventory"
      this.addinventorybtn = false;
      this.editinventorybtn = true; 
      $("#InventoryModal").modal('show');
      this.addInventoryForm.controls['id'].setValue(element['id']);
      this.addInventoryForm.controls['name'].setValue(element['name']);
      this.addInventoryForm.controls['description'].setValue(element['description']);
      this.addInventoryForm.controls['giventotheuser'].setValue(element['giventotheuser']);
      this.addInventoryForm.controls['status'].setValue(element['status']);
      let join_Array =  element['date_inventory'].split("-");
      const date_inventory = new Date(join_Array[2], join_Array[1]-1, join_Array[0]);
      this.addInventoryForm.controls['date_inventory'].setValue(date_inventory);
   }

   updateInventory(){
    this.http.post(Api.url.adminSaveInventory, this.addInventoryForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Edit Inventory Successfully","","green-snackbar");
         $("#InventoryModal").modal('hide');
         $('#myform')[0].reset();
         this.InventoryList(this.searchvalue,this.pageSize,this.currentPage);
         this.editinventorybtn = false;
         this.addinventorybtn = true;
         this.title_section = "Add Inventory"

      }
    });
   }

   deleteInventory(id:any){
    $("#delinventory").modal("show");
    this.inventory_id = id;
    // console.log(id);
   }

   delete_inventory()
   {
    this.http.post(Api.url.admininventoryDelete,{id:this.inventory_id}).then((response: any)=>{
      $("#delinventory").modal('hide');
      this.InventoryList(this.searchvalue,this.pageSize,this.currentPage);
      this.openSnackBar("Delete Inventory Successfully","","green-snackbar");
    });

   }
}
