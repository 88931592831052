<div class="mat-elevation-z8">


	<div class="col-4 ps-2 pb-3 pt-4">
		<div class="col-auto">
			<label class="visually-hidden" for="search">Search</label>
			<input type="text" (keyup)="timeSheet($event)" class="form-control" id="search" placeholder="Search">
		</div>
	</div>


	<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

		<!-- Position Column -->
		<ng-container matColumnDef="timedate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
			<td mat-cell *matCellDef="let element let i = index"> {{element.timedate}} </td>
		</ng-container>

		<ng-container matColumnDef="startime">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Star Time </th>
			<td mat-cell *matCellDef="let element"> {{element.startime}} </td>
		</ng-container>

		<ng-container matColumnDef="endtime">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> End Time </th>
			<td mat-cell *matCellDef="let element"> {{element.endtime}} </td>
		</ng-container>

		<ng-container matColumnDef="projname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
			<td mat-cell *matCellDef="let element"> {{element.projname}} </td>
		</ng-container>

		<ng-container matColumnDef="taskname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Task Name </th>
			<td mat-cell *matCellDef="let element"> {{element.taskname}} </td>
		</ng-container>

		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
			<td mat-cell *matCellDef="let element"> {{element.description}} </td>
		</ng-container>

		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
			<td mat-cell *matCellDef="let element">
				<mat-icon class="example-icon edit-icon" (click)="getTimeSheetById(element)">edit</mat-icon>
			</td>
		</ng-container>


		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
</div>
<mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage"
	[pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
</mat-paginator>

<div class="modal hide" id="timesheetModal">
	<div class="modal-dialog  modal-dialog-centered">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title">Update Timesheet</h4>
				<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
			</div>

			<!-- Modal body -->
			<div class="modal-body">
				<div mat-dialog-content>
					<form [formGroup]="timesheetForm" name="edittimesheet">
						<mat-grid-list cols="2" rowHeight="2:1">
							<mat-grid-tile>
								<mat-form-field>
									<input matInput tabindex="1" placeholder="Start Time" readonly formControlName="startime"
										name="startime">
								</mat-form-field>
								<mat-form-field>
									<input matInput tabindex="1" placeholder="End Time" readonly formControlName="endtime" name="endtime">
								</mat-form-field>
							</mat-grid-tile>
							<mat-grid-tile>
								<mat-form-field>
									<input matInput tabindex="1" placeholder="Project Name" readonly formControlName="projectname"
										name="projectname">
								</mat-form-field>
								<mat-form-field>
									<input matInput tabindex="1" placeholder="Task Name" readonly formControlName="taskname"
										name="taskname">
								</mat-form-field>
							</mat-grid-tile>
						</mat-grid-list>
						<mat-grid-list cols="1" rowHeight="100px">
							<mat-grid-tile>
								<mat-form-field>
									<input name="id" type="hidden" formControlName="userid" name="userid">
									<input name="id" type="hidden" formControlName="id" name="id">
									<textarea matInput placeholder="Description" formControlName="description" name="description">
                    </textarea>
								</mat-form-field>
							</mat-grid-tile>
						</mat-grid-list>
					</form>
				</div>
				<div mat-dialog-actions style="float: right;">
					<button mat-raised-button color="primary" tabindex="2" (click)="saveTask()">Update</button>
					<!-- <button  mat-raised-button color="warn" (click)="onNoClick()">Cancel</button> -->
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->