<div class="mat-elevation-z8">
    <div class="row g-3 section">
      <div class="col-sm-4">
          <div class="col-auto" >
              <label class="visually-hidden" for="search">Search</label>
              <input type="text" (keyup)="SearchResume($event)" class="form-control" id="search" placeholder="Search">
          </div>
      </div>
      <div class="col-sm-4 mobile-none"></div>
      <div class="col-sm-4">
        <button mat-raised-button color="primary" class="user-btn width-100" data-bs-toggle="modal" data-bs-target="#ResumeModal" >Add Resume</button>
      </div>
    </div>
    <div class="example-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
            <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
          </ng-container>
      
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
      
          <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Department </th>
            <td mat-cell *matCellDef="let element"> {{element.department}} </td>
          </ng-container>
      
          <ng-container matColumnDef="contact_no">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> contact_no </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}}</td>
          </ng-container>
      
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>  Address  </th>
            <td mat-cell *matCellDef="let element"> {{element.address}}</td>
          </ng-container>
      
          <ng-container matColumnDef="experience">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>  Experience  </th>
            <td mat-cell *matCellDef="let element">  {{element.experience}} </td>
          </ng-container>
      
          <ng-container matColumnDef="iscontacted">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Iscontacted  </th>
            <td mat-cell *matCellDef="let element">  {{element.iscontacted}} </td>
          </ng-container>

          <ng-container matColumnDef="dateofcontact">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Dateofcontact  </th>
            <td mat-cell *matCellDef="let element">  {{element.dateofcontact}} </td>
          </ng-container>
      
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="example-icon edit-icon" (click)="editResume(element)">edit</mat-icon>
                <mat-icon class="example-icon delete-icon" (click)="deleteResume(element.id)">delete</mat-icon>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
    </mat-paginator>
</div>
  <div class="modal fade" id="delresume" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Resume</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="deleteresume()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
  </div>

  <div class="modal hide" id="ResumeModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">{{title_section}}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <form [formGroup]="addResumeForm" name="adduser" style="margin-top:-45px;" id="myform">
            <input matInput value="" placeholder="Enter id" #message formControlName="resumeid" name="resumeid" style="visibility:hidden;" >
            <mat-form-field>
              <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addResumeForm.get('name')?.invalid && addResumeForm.get('name')?.touched && addResumeForm.get('name')?.errors?.required )">
                   Name is required
            </span>

            <mat-form-field appearance="fill">
              <mat-label>Department </mat-label>
              <mat-select formControlName="department">
                <mat-option *ngFor="let des of desOptions" [value]="des.id">
                  {{des.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
           
            <span class="text-danger"
              *ngIf="(addResumeForm.get('department')?.invalid && addResumeForm.get('department')?.touched && addResumeForm.get('department')?.errors?.required )">
              Department is required
            </span>  

            <mat-form-field>
              <input matInput value="" placeholder="Enter contact Number" #message formControlName="phone" name="phone">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addResumeForm.get('phone')?.invalid && addResumeForm.get('phone')?.touched && addResumeForm.get('phone')?.errors?.required )">
                Contact Number is required
            </span>

            <mat-form-field>
              <input matInput value="" placeholder="address" #message formControlName="address" name="address">
            </mat-form-field>

            <span class="text-danger"
            *ngIf="(addResumeForm.get('address')?.invalid && addResumeForm.get('address')?.touched && addResumeForm.get('address')?.errors?.required )">
               Address is required
            </span> 

            <mat-form-field appearance="fill">
              <mat-label>Experience </mat-label>
              <mat-select formControlName="experience">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>

            <span class="text-danger"
              *ngIf="(addResumeForm.get('designation')?.invalid && addResumeForm.get('designation')?.touched && addResumeForm.get('designation')?.errors?.required )">
                Experience is required
            </span>  

            <mat-form-field appearance="fill">
              <mat-label>IsContacted </mat-label>
              <mat-select formControlName="iscontacted" (selectionChange)="onSelectEvent($event.value)">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field *ngIf="contact_contition">
              <input matInput value=""  placeholder="dateofcontact" #message formControlName="dateofcontact" name="dateofcontact">
            </mat-form-field> -->

            <mat-form-field *ngIf="contact_contition" class="example-full-width" appearance="fill" (click)="picker2.open()">
              <mat-label>Contact Date</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="dateofcontact" name="dateofcontact">
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <div mat-dialog-actions style="float: right;">
                <button type="submit" *ngIf="addresumebtn" [disabled]="addResumeForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addResume()">Save</button>
                <span class="margin-right-20"></span>
                <button type="submit" *ngIf="editresumebtn" mat-raised-button color="primary" tabindex="2" (click)="updateResume()">Update</button>
                <span class="margin-right-20"></span>
                <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>