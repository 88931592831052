import { Component } from '@angular/core';
import { AuthGuard } from './services/auth.guard';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	isLoggedIn = false;

	constructor(private auth: AuthGuard) {

		this.isLoggedIn = this.auth.isUserLoggedin();
		this.auth.loginChange.subscribe(() => {

			this.isLoggedIn = this.auth.isUserLoggedin();
		})

	}


}
