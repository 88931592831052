<!-- <div fxLayout="row">
  <div fxFlex.gt-sm="100%">
  <mat-form-field appearance="outline" style="width:40%;float: right;" >
      <mat-label>Select Name </mat-label>
      <mat-select (selectionChange)="onSelectEvent($event.value)">
              <mat-option *ngFor="let des of names" [value]="des.name">
                  {{des.name}}
              </mat-option>
      </mat-select>
  </mat-form-field>
  </div>
</div> -->
<div class="mat-elevation-z8">
  <div class="row g-3 section">
   
    <div class="col-sm-4 mobile-none"></div>
  
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <div class="dropdown">
        <div class="input-group">
            <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                <option selected value="0">Select Username</option>
                <ng-container *ngFor="let des of names">
                    <option value="{{des.name}}">{{des.name}}</option>
                </ng-container>
            </select>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <ng-container matColumnDef="checkin">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> CheckIn </th>
          <td mat-cell *matCellDef="let element"> {{element.createtime}} </td>
        </ng-container>

        <ng-container matColumnDef="checkout">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Checkout </th>
          <td mat-cell *matCellDef="let element"> {{element.checkout}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}}</td>
        </ng-container>

        <ng-container matColumnDef="total_time">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Total Time </th>
            <td mat-cell *matCellDef="let element"> {{element.totaltime}}</td>
        </ng-container>
      
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="example-icon edit-icon" (click)="editProject(element)">edit</mat-icon>
                <mat-icon class="example-icon delete-icon" (click)="deleteProject(element.id)">delete</mat-icon>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
    </mat-paginator>

</div>