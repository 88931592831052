<div class="mat-elevation-z8">
  <div fxLayout="row">
    <div fxFlex.gt-sm="100%" class="section feedback-btn">
        <button mat-raised-button color="primary" class="user-btn feedback-btn" data-bs-toggle="modal" data-bs-target="#FeedbackModal" >Add FeedBack</button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Organization </th>
          <td mat-cell *matCellDef="let element"> {{element.organization}} </td>
        </ng-container>

        <ng-container matColumnDef="feedback">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> FeedBack </th>
          <td mat-cell *matCellDef="let element"> {{element.feedback}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
          <td mat-cell *matCellDef="let element">
              <mat-icon class="example-icon edit-icon" (click)="editFeedback(element)">edit</mat-icon>
              <mat-icon class="example-icon delete-icon" (click)="delFeedback(element.id)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<div class="modal fade" id="delfeedback" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete FeedBack</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="deleteFeedback()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
</div>

<div class="modal hide" id="FeedbackModal">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">Add FeedBack</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addFeedbackForm" name="adduser" style="margin-top:-45px;" id="myform">
          <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id" style="visibility:hidden;" >
          <mat-form-field>
            <textarea matInput value="" placeholder="Enter FeedBack" #message formControlName="feedback" name="feedback"></textarea>
          </mat-form-field>
      
          <span class="text-danger"
             *ngIf="(addFeedbackForm.get('feedback')?.invalid && addFeedbackForm.get('feedback')?.touched && addFeedbackForm.get('feedback')?.errors?.required )">
              Feedback is required
          </span>

    
          <div mat-dialog-actions style="float: right;">
              <button type="submit" *ngIf="addfeedbackbtn" [disabled]="addFeedbackForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addFeedback()">Save</button>
              <span class="margin-right-20"></span>
              <button type="submit" *ngIf="editfeedbackbtn" mat-raised-button color="primary" tabindex="2" (click)="updateFeedback()">Update</button>
              <span class="margin-right-20"></span>
              <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
