<div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Position Column -->
          <ng-container matColumnDef="h_date">
            <th mat-header-cell *matHeaderCellDef> Holiday Date </th>
            <td mat-cell *matCellDef="let element"> {{element.h_date}} </td>
          </ng-container>
      
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description  </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
</div>
        <!-- <mat-paginator [length]="5" [pageSize]="3" [pageSizeOptions]="[5, 10, 25]">
        </mat-paginator> -->
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
