import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Api } from 'src/app/services/api.service';
import { HttpService } from 'src/app/services/http.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  AbstractControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';



declare var ClassicEditor;
declare var moment;
declare var $;

@Component({
  selector: 'app-admindocument',
  templateUrl: './admindocument.component.html',
  styleUrls: ['./admindocument.component.css'],

})

export class AdmindocumentComponent implements OnInit, AfterViewInit {
  isSubmitted = false;
  deleteUserId: any;
  dataSource: any = [];
  isAdd:boolean;
  isupdate:boolean;
  id:any;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  length: any;
  pageSize = 10;
  @ViewChild(MatSort) sort: MatSort | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  currentPage: number;

  constructor(private auth: Api,private httpclient:HttpClient ,private _liveAnnouncer: LiveAnnouncer, protected http: HttpService, public formBuilder: FormBuilder) { }
  CKEditor: any;
  ngOnInit(): void {
    this.isAdd = true;
    this.isupdate = false;
    this.getDocumentlist(this.currentPage,this.pageSize);

    $('#addDocument').on('hidden.bs.modal', () => {
      // console.log(ClassicEditor.instances[0]);
      this.CKEditor.setData('');
      this.addForm.reset();

      this.isSubmitted = false;
    });


  }

  ngAfterViewInit(): void {
    ClassicEditor.create(document.querySelector('#editor')).then(editor => {

      this.CKEditor = editor
      editor.model.document.on('change:data', (e, data) => {
        this.addForm.controls['content'].setValue(editor.getData());
      });

    })
      .catch(error => {
        console.error(error);
      });

  }

  private getDocumentlist(currentPage:any,pageSize:any) {
    this.http.post(Api.url.adminDocumentList,{page:currentPage,recordPerPage:pageSize}).then((data: any) => {
      this.dataSource = new MatTableDataSource(data.data.data);
      this.dataSource.sort = this.sort;
      this.paginator.length = data.data.total;
      this.length = data.data.total;
    })

  }
  changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.getDocumentlist(page,this.pageSize);
   }
  displayedColumns: string[] = ['no', 'type', 'date', 'todate', 'gstno', 'actions'];

  addForm = this.formBuilder.group({
    addType: ['', [Validators.required]],
    selectDate: ['', [Validators.required]],
    enterTo: ['', [Validators.required]],
    gst: [''],
    content: ['', [Validators.required]],
    id: ['']

  })

  get formElement(): { [key: string]: AbstractControl } {
    return this.addForm.controls;
  }



  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction} ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  deleteData(data: any) {
    this.deleteUserId = data;
    console.log("hiii");
    console.log("DATA", this.deleteUserId);
  }

  deleteUserRecord() {
    let id = this.deleteUserId
    this.http.post(Api.url.adminDocumentDeleteFeedback, { id }).then((data: any) => { })
    this.getDocumentlist(this.currentPage,this.pageSize);

  }


  addDocument() {
    // console.log(this.addForm.value.selectDate);
    this.isSubmitted = true;
    if (!this.addForm.valid) {
      return false;
    }
    else {
      const type = this.addForm.value.addType;
      const enterTo = this.addForm.value.enterTo;
      const gst = this.addForm.value.gst;
      const date = this.addForm.value.selectDate
      const today = moment();
      const content = this.addForm.value.content

      const parseDate = moment(date).format('YYYY-MM-DD')
      // console.log(date);

      this.http.post(Api.url.adminDocumentAdd, { dtype: type, date: parseDate, content: content, gstno: gst, description: enterTo }).then((data: any) => {
      });
      $("#addDocument").modal('hide');
      this.getDocumentlist(this.currentPage,this.pageSize);
    }
  }

  editDocument(data: any) {
    this.isupdate = true;
    this.isAdd = false;
    
    const momentDate = moment(data.date, 'YYYY-MM-DD');
    const dateObject = momentDate.toDate();
    console.log(momentDate._i);
    // console.log(data.date);
    // console.log(new Date(data.date));
    
    this.id = data.id;
    this.addForm.patchValue({
      addType: data.type,
      selectDate: new Date(data.date),
      enterTo: data.d_to,
      content: data.content,
      gst: data.isGSTNo
    });

    this.CKEditor.setData(data.content);
    console.log(data.date);

  }
  
  updateDocument()
   {
    const type = this.addForm.value.addType;
    const enterTo = this.addForm.value.enterTo;
    const gst = this.addForm.value.gst;
    const date = this.addForm.value.selectDate
    const today = moment();
    const content = this.addForm.value.content
    const parseDate = moment(date).format('YYYY-MM-DD');

     this.http.post(Api.url.adminDocumentUpdate,{ id: this.id,dtype: type, date: parseDate, content: content, gstno: gst, description: enterTo}).then((response: any) => {
      if (response.success)
       {
        //  this.openSnackBar("Edit Resume Successfully","","green-snackbar");
          $("#addDocument").modal('hide');
          $('#myform')[0].reset();
          this.isupdate = false;
          this.getDocumentlist(this.currentPage,this.pageSize);
      }
    });
   }

   downloadDocument(id:any){
    let  headerOptions:any = this.auth.getTradeshowHeaders();
    headerOptions['Content-Type'] = 'application/json';
    headerOptions['Accept'] = 'application/pdf';
     
     headerOptions = new HttpHeaders(headerOptions);

    let requestOptions = { headers:headerOptions, responseType: 'blob' as 'blob' };

    this.httpclient.get(`${Api.url.adminDownloadDocument}?id=${id}`,requestOptions).subscribe((response: any) => {
        
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', 'download.pdf');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      
    });
   }
}
