import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase, ref, set, onValue, push, DataSnapshot, remove} from "firebase/database";
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Chat } from '../../chat/chat';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from '../services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { ObservedValueOf } from 'rxjs';

@Component({
	selector: 'app-firestore-chat',
	templateUrl: './firestore-chat.component.html',
	styleUrls: ['./firestore-chat.component.css']
})
export class FirebaseChatComponent implements OnInit {
	

	title = 'fire-chat';
	app: FirebaseApp;
	db: Database;
	form: FormGroup;
	username = '';
	message = '';
	chats: Chat[] = [];
	isChatVisible = false;
	selected = 'None';
	userList: any;
	loginUserID: any;
	loginUserName: any;
	selectedUserName: any;
	selectedUserID: any;
	messageUniqueID: any;
	chatRoomData: any;
	chatRoomExistsStatus = false;
	OneToOneUserChatData: any;
	chatRoomID: any;
	checkInStatus:false;
	groupChatData: any;
	addUsersToGroups: any;
	selectedUsersForGroup: any;
	currentUserGroupList: any = [];
	groupUniqueID: any;
	groupChathistroy:any = "No Messages yet";
	erpTestGroupChatHistroy: any;
	noGroupChatHistroyFound = true;
	chatBoxTitle: any;
	isGroupChat: any;
	isGroupUsers = false;
	groupKeyForGroupUserList: any;
	groupUsersListToshow = [];
	activeGroupDetails = [];
	currentGroupUsreList: any;
	addNewUsersToGroups: any;
	addNewMembersToGroupList = [];
	groupCreatorId: any;
	userRecentChatsList: any;




	@ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
	
	constructor(private formBuilder: FormBuilder, private http: HttpService, private api: Api, private auth: AuthGuard, ) {
		this.app = initializeApp(environment.firebase);
		this.db = getDatabase(this.app);
		this.form = this.formBuilder.group({
			'message': [],
			'username': []
		});
	}

	ngOnInit(): void {
		this.listingUsers();
		this.http.get(Api.url.userListChat, {}).then((data: any) => {
			this.userList = data.data;
		});
		this.getLoginUserData();
		// Scroll chats
		this.scrollBottom();

		let erpGroupChatsRoomsRef = ref(this.db, `erp-group-chats-rooms/bmac-group-chat/message`);
		onValue(erpGroupChatsRoomsRef, (snapshot) => {
			this.groupChatData = snapshot.val()
		})

		this.findGroups(this.loginUserID);

		this.recentChatList();
	}
	
	async usernamesForChat(event: any){
		this.isGroupUsers = false;
		let user_1_ID = this.loginUserID;
		if(event.type != "click"){
			this.selectedUserName = event.source.triggerValue;
			this.selectedUserID = event.value;
			let userChatsData = await this.getValues(`erpchats-rooms/chatRoomID_${user_1_ID}_${event.value}/message`);
			if(!userChatsData.size) {
				userChatsData = await this.getValues(`erpchats-rooms/chatRoomID_${event.value}_${user_1_ID}/message`);
			}
			this.OneToOneUserChatData = userChatsData.val()
		} else {
			this.selectedUserName = event.target.value;
			this.selectedUserID =  event.target.id;
			let userChatsData = await this.getValues(`erpchats-rooms/chatRoomID_${user_1_ID}_${event.target.id}/message`);
			if(!userChatsData.size) {
				userChatsData = await this.getValues(`erpchats-rooms/chatRoomID_${event.target.id}_${user_1_ID}/message`);
			}
			this.OneToOneUserChatData = userChatsData.val()
		}
		this.chatBoxTitle = this.selectedUserName;
		this.isGroupChat = 1;
		
		this.scrollBottom();
	}

	getLoginUserData(){
		let loginUserData: any = JSON.parse(localStorage.getItem("logindata") || "[]");
		this.loginUserID = loginUserData.user.id;
		this.loginUserName = loginUserData.user.name;
	}
	commonGroup(e){
		this.isGroupUsers = false;
		this.chatBoxTitle = e.target.value;
		this.isGroupChat = 2;
	}

	async onChatSubmit(form: any) {
		// alert(JSON.stringify(form))
		let timestamp = this.getDateTimeFormat();

		let user_1_ID = this.loginUserID;
		let user_2_ID = Number(this.selectedUserID);
		// let chatRoomID = `chatRoomID_${user_1_ID}_${user_2_ID}`;

		let inputMessage:any = document.getElementById("inputMessage");
		if(inputMessage.value != ""){
			let chatRoomData = await this.getValues(`erpchats-rooms/chatRoomID_${user_1_ID}_${user_2_ID}`);
			if(!chatRoomData.size) {
				chatRoomData = await this.getValues(`erpchats-rooms/chatRoomID_${user_2_ID}_${user_1_ID}`);
			}
	
			if(!chatRoomData.size) {
				const obj = {
					user1ID: user_1_ID,
					user1name: this.loginUserName,
					user2ID: user_2_ID,
					user2name: this.selectedUserName,
					timestamp,
				}
				set(chatRoomData.ref, obj)
			}
			let newChat = {
				senderID: user_1_ID,	
				text: inputMessage.value,
				timestamp,
			}
			push(ref(this.db, `erpchats-rooms/${chatRoomData.key}/message/`), newChat)
			// reflect changes
			let erpChatsRoomsRef = ref(this.db, `erpchats-rooms/${chatRoomData.key}/message`);
			onValue(erpChatsRoomsRef, (snapshot) => {
				// console.log("here", snapshot);
				this.OneToOneUserChatData = snapshot.val();
			})
			this.chatRoomID = chatRoomData.key;
			// console.log(this.OneToOneUserChatData)
			// this.OneToOneUserChatData = ref(this.db, `erpchats-rooms/${chatRoomData.key}/message/`)
			inputMessage.value = "";
			
		
			// recent chats add to firebase timestamp
			// this.updateAddRecentChats(id1, id2, name)
			this.updateAddRecentChats(this.loginUserID, this.selectedUserID, this.selectedUserName)
			this.updateAddRecentChats(this.selectedUserID, this.loginUserID, this.loginUserName)
			
			// let userUniqueID = `user_${this.loginUserID}`;
			// let recentChatkey = "";
			// let recentChats  = await this.getValues(`erpchats-rooms/recent-chats/${userUniqueID}`);
			// let recentChatData = (await this.getValues(`erpchats-rooms/recent-chats/${userUniqueID}/`)).val();
			// const newRecentChat = {
			// 	reciverId: this.selectedUserID,
			// 	reciverName: this.selectedUserName,
			// 	timestamp
			// }
			// if(!recentChats.size){
			// 	push(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}`), newRecentChat)
			// } else {
			// 	Object.entries(recentChatData).forEach(([key, value])=>{
			// 		if(value['reciverId'] == this.selectedUserID){
			// 			recentChatkey = key;
			// 		}
			// 	})
			// 	// console.log("Recent chat key is", recentChatkey)
				
			// 	if(recentChatkey == ""){
			// 		push(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}`), newRecentChat)
			// 	} else {
			// 		set(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}/${recentChatkey}/`), newRecentChat);
			// 	}
			// }


			// // Update recent chat data of selected user
			// let updateRecentSecondUserChat = {
			// 	reciverId: this.loginUserID,
			// 	reciverName: this.loginUserName,
			// 	timestamp
			// }
			// let recentSecondChats  = await this.getValues(`erpchats-rooms/recent-chats/user_${this.selectedUserID}`);
			// let recentSecondUserChat = (await this.getValues(`erpchats-rooms/recent-chats/user_${this.selectedUserID}/`)).val();
			// if(!recentSecondChats.size){
			// 	push(ref(this.db, `erpchats-rooms/recent-chats/user_${this.selectedUserID}`), updateRecentSecondUserChat)
			// } else {
			// 	Object.entries(recentSecondUserChat).forEach(([key, value])=>{
			// 		if(value['reciverId'] == this.loginUserID){
			// 			recentChatkey = key;
			// 		}
			// 	})
			// 	// console.log("Recent chat key is", recentChatkey)
				
			// 	if(recentChatkey == ""){
			// 		push(ref(this.db, `erpchats-rooms/recent-chats/user_${this.selectedUserID}`), updateRecentSecondUserChat)
			// 	} else {
			// 		set(ref(this.db, `erpchats-rooms/recent-chats/user_${this.selectedUserID}/${recentChatkey}/`), updateRecentSecondUserChat);
			// 	}
			// }

			// this.recentChatList();
			this.scrollBottom();
			

		} else { 
			alert("Please select user !! Write Some Thing")
		}
	}


	
	async updateAddRecentChats(updateUserId, receverid, name){
		let timestamp = this.getDateTimeFormat();
		let userUniqueID = `user_${updateUserId}`;
			let recentChatkey = "";
			let recentChats  = await this.getValues(`erpchats-rooms/recent-chats/${userUniqueID}`);
			let recentChatData = (await this.getValues(`erpchats-rooms/recent-chats/${userUniqueID}/`)).val();
			const newRecentChat = {
				reciverId: receverid,
				reciverName: name,
				timestamp
			}
			if(!recentChats.size){
				push(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}`), newRecentChat)
			} else {
				Object.entries(recentChatData).forEach(([key, value])=>{
					if(value['reciverId'] == receverid){
						recentChatkey = key;
					}
				})
				// console.log("Recent chat key is", recentChatkey)
				
				if(recentChatkey == ""){
					push(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}`), newRecentChat)
				} else {
					set(ref(this.db, `erpchats-rooms/recent-chats/${userUniqueID}/${recentChatkey}/`), newRecentChat);
				}
			}
		this.recentChatList();
	}



	async onGroupChatSubmit(){
		
		let groupChatMessage: any = document.getElementById('inputgroupChatMessage')
		let timestamp = this.getDateTimeFormat();
		if(groupChatMessage.value == ""){
			alert("Write Some Thing")
		} else{
			// console.log(groupChatMessage.value)
			let groupChatRoomName = "bmac-group-chat"
			let groupChatRoomData = await this.getValues(`erp-group-chats-rooms/${groupChatRoomName}`);

			if(!groupChatRoomData.size){
				const obj = {
					group_name: groupChatRoomName,
					timestamp,
				}
				set(groupChatRoomData.ref, obj)
			}
			let gruopChatData = {
				sender_id: this.loginUserID,
				sender_name: this.loginUserName,
				text: groupChatMessage.value,
				timestamp: timestamp
			}

			push(ref(this.db, `erp-group-chats-rooms/${groupChatRoomData.key}/message/`), gruopChatData);



			let erpGroupChatsRoomsRef = ref(this.db, `erp-group-chats-rooms/${groupChatRoomData.key}/message`);
			onValue(erpGroupChatsRoomsRef, (snapshot) => {
				this.groupChatData = snapshot.val()
			})
			this.scrollBottom();
			groupChatMessage.value = "";
		}
	}
	showChat() {
		this.isChatVisible = !this.isChatVisible;
	}

	getValues(path) {
		return new Promise<DataSnapshot>((resolve) => {
			const erpChatsRoomsRef = ref(this.db, path);
			onValue(erpChatsRoomsRef, (snapshot) => {
				resolve(snapshot);
			})
		})
	}


	


	groupUserList(selectedUsersId){
		// console.log("Selecton values", selectedUsersId.value)
		// this.selectedUsersForGroup = selectedUsersId.value
	}
	
	async onCreateGroupFromSubmit(){
		let groupName: any = document.getElementById('gruopName') 
		if(groupName.value == ""){
			alert("Please add group name")
		} else {
			let timestamp = this.getDateTimeFormat();
			// console.log(this.userList)
			let groupMembersList = this.groupUsersList(); 
			let groupChatRoomName = groupName.value
			let groupChatRoomData = await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats`);

			const obj = {
				group_name: groupChatRoomName,
				// createdBy: this.loginUserName,
				creatorName: this.loginUserName,
				creatorID: this.loginUserID,
				members: '',
				timestamp,
			}
			let lastKey = push(ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats`), obj).key;
			
			for(let i = 0; i < groupMembersList.length; i++){
				let membersListObj = {
					group_member_name: groupMembersList[i].name,
					group_member_id: groupMembersList[i].id
				}
				push(ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${lastKey}/members`), membersListObj);
				this.findGroups(groupMembersList[i].id);
			}

			let usersGroupsRef = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups`))
			if(!usersGroupsRef.size){
				usersGroupsRef = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups`))
			}
			
			for(let i = 0; i < groupMembersList.length; i++){
				let userUniqueID = `user_${groupMembersList[i].id}`;
				let userGroupsData =  (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${lastKey}/`))
				let userGroupsobj = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`))
				if(!userGroupsobj.size){
					set(ref(this.db, `bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`), [userGroupsData.key])
				}
				else {
					let data = userGroupsobj.val();
					data.push(userGroupsData.key);
					set(ref(this.db, `bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`), data);
				}
			}
		}
		this.findGroups(this.loginUserID);



	}
	/*
	
	*/
	groupUsersList(){

		let groupUserList = this.userList.filter(e => this.addUsersToGroups.includes(e.id.toString()));
		groupUserList.push({
			id: this.loginUserID,
			name: this.loginUserName,
		})

		return groupUserList
	}


	async recentChatList(){
		let userRecentChatData =  (await this.getValues(`erpchats-rooms/recent-chats/user_${this.loginUserID}/`)).val();
		// console.log("userRecentChatData", userRecentChatData);
		this.userRecentChatsList = [];
		Object.entries(userRecentChatData).forEach(([key, value])=>{
			this.userRecentChatsList.push({
				name: value['reciverName'],
				id: value['reciverId'],
				timestamp: value['timestamp']
			})
		})

		this.userRecentChatsList.sort((a, b)=>{
			// Date.parse(a.timestamp)
			return Date.parse(b.timestamp) - Date.parse(a.timestamp)
		})
			// console.log(this.userRecentChatsList, "sorted data")
	}	

	async findGroups(loginid: any){
		let userGroupsListsKeys = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups/user_${loginid}`)).val();
		// console.log(userGroupsListsKeys)
		this.currentUserGroupList = [];
		userGroupsListsKeys.map(async (groupKeys) => {
			let groupsData = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${groupKeys}`)).val();
			this.currentUserGroupList.push({
				gpName: groupsData.group_name,
				gpId: groupKeys
			});
		})
	}

	async findGroupName(groupID){
		
		this.isGroupChat = 3;
		this.isGroupUsers = false;
		this.groupKeyForGroupUserList = groupID.target.value;
		this.noGroupChatHistroyFound = false;
		// console.log("groupID", groupID.target.value)
		let groupsChatData = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${groupID.target.value}/chats`)).val();
		if(!groupsChatData){
			this.groupChathistroy = "No Messages yet"
		}
		this.groupUniqueID = groupID.target.value;
		this.chatBoxTitle = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${groupID.target.value}`)).val().group_name;

		let erpTestGroupChatsRoomsRef = ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${groupID.target.value}/chats/`);
		onValue(erpTestGroupChatsRoomsRef, (snapshot) => {
			this.erpTestGroupChatHistroy = snapshot.val()
		})
		this.scrollBottom();
	}

	async onGroupsChatSubmit(){
		let timestamp = this.getDateTimeFormat();
		let groupsData = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${this.groupUniqueID}/chats`));
		if(!groupsData.size){
			this.groupChathistroy = "No Messages yet"
		}
		let mess: any = document.getElementById('groupChatMessage');
		// console.log("Hello",mess.value)
		if(mess.value == null){
			alert("Write Some thing");
			return;
		}
		let newGroupChat = {
			sender_id: this.loginUserID,
			sender_name: this.loginUserName,
			text: mess.value,
			timestamp
		}

		push(ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${this.groupUniqueID}/chats/`), newGroupChat)

		let erpTestGroupChatsRoomsRef = ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${this.groupUniqueID}/chats/`);
		onValue(erpTestGroupChatsRoomsRef, (snapshot) => {
			this.erpTestGroupChatHistroy = snapshot.val()
		})
		
		this.scrollBottom();
		mess.value = "";
	}


	async viewGroupUsers(e){
		this.isGroupUsers = true;
		// let activeGroupMembersList = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${e.target.value}/members`))
		let groupDetails = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${e.target.value}/`)).val();
		// console.log("group details", groupDetails.createdBy(timestamp))
		this.groupUsersListToshow = [];
		this.activeGroupDetails = [];
		this.groupCreatorId = groupDetails.creatorID;
		let creatorName = "";
		if((groupDetails.creatorName || groupDetails.createdBy) == this.loginUserName){
			creatorName = this.loginUserName
		} else {
			creatorName = groupDetails.creatorName
		}
		this.activeGroupDetails.push({
			createdBy: creatorName,
			createdAt: groupDetails.timestamp
		});
		Object.entries(groupDetails.members).forEach(([key, value]) => {
			this.groupUsersListToshow.push({
				gpMemberId: value['group_member_id'],
				gpMemberName: value['group_member_name'],
			})
		});
	}

	async addNewGroupMembers(){
		let addMembersFormUserList = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${this.groupKeyForGroupUserList}/members`)).val();
		
		this.addNewMembersToGroupList = [];
		let userIds = [];
		Object.entries(addMembersFormUserList).forEach(([key, value]) => {
			userIds.push(value['group_member_id'])
		});

		this.addNewMembersToGroupList = this.userList.filter((item) => {
			return userIds.indexOf(item.id) == -1;
		});
		
	}

	addMemberTothisGroup(e){
		// console.log("This is add members list",this.addNewUsersToGroups)
		// // let newMembersList = this.addNewMembersToGroupList.filter(e => this.addNewUsersToGroups.includes(e.id.toString()));
		// let newMembers = this.newMembersFiltersList(this.addNewMembersToGroupList, this.addNewUsersToGroups);
		// console.log("New Members List is", newMembers);
		
	}


	newMembersFiltersList(userList, ids){
		let newMembersList = userList.filter(e => ids.includes(e.id.toString()));
		return newMembersList;
	}


	async addMembers(){
		let newMembers = this.newMembersFiltersList(this.addNewMembersToGroupList, this.addNewUsersToGroups);
		// console.log("New Members List is", newMembers);

		for(let i = 0; i < newMembers.length; i++){
			let membersListObj = {
				group_member_name: newMembers[i].name,
				group_member_id: newMembers[i].id
			}
			this.groupUsersListToshow.push({
				gpMemberId: newMembers[i].id,
				gpMemberName: newMembers[i].name,
			})
			push(ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${this.groupKeyForGroupUserList}/members`), membersListObj);
		}

		let usersGroupsRef = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups`))
		if(!usersGroupsRef.size){
			usersGroupsRef = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups`))
		}
		
		for(let i = 0; i < newMembers.length; i++){
			let userUniqueID = `user_${newMembers[i].id}`;
			let userGroupsData =  (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${this.groupKeyForGroupUserList}/`))
			let userGroupsobj = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`))
			if(!userGroupsobj.size){
				set(ref(this.db, `bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`), [userGroupsData.key])
			}
			else {
				let data = userGroupsobj.val();
				data.push(userGroupsData.key);
				set(ref(this.db, `bmac-erp-group-chats-rooms/userGroups/${userUniqueID}`), data);
			}
		}
	}



	async removeGroupMembers(e){
		if(confirm("Are You Sure?")){
			// console.log("Group Id is", e.target.value);
			let userId = `user_${e.target.id}`;
			let removeUserRef = (await this.getValues(`bmac-erp-group-chats-rooms/userGroups/${userId}`)).val()
			// console.log("Remove user Group details", removeUserRef);
			let newGroupList = removeUserRef.filter((items)=>{
				return items !== e.target.value
			});
			set(ref(this.db, `bmac-erp-group-chats-rooms/userGroups/${userId}`), newGroupList);
			let membersList = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${e.target.value}/members`)).val();
			// console.log(membersList)
			let removeUserKey = "";
			Object.entries(membersList).forEach(([key, value])=>{
				if(value['group_member_id'] == e.target.id){
					removeUserKey = key;
				}
			})
			// console.log("Remove User", removeUserKey)
			remove(ref(this.db, `bmac-erp-group-chats-rooms/groups_with_chats/${e.target.value}/members/${removeUserKey}`))
	
	
			let groupDetails = (await this.getValues(`bmac-erp-group-chats-rooms/groups_with_chats/${e.target.value}/`)).val();
			this.groupUsersListToshow = [];
			Object.entries(groupDetails.members).forEach(([key, value]) => {
				this.groupUsersListToshow.push({
					gpMemberId: value['group_member_id'],
					gpMemberName: value['group_member_name'],
				})
			});
		}
		return;

	}
	listingUsers() {
		this.http.get(Api.url.userListChat, {}).then((data: any) => {
			this.userList = data.data;
		});
	}

	getDateTimeFormat(){
		let date = new Date();
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let seconds = date.getSeconds();
		return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
	}

	scrollBottom() {
		setTimeout(()=>{
			this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
		}, 500)
	}
	/* 
	erp-group-chats-rooms{
		userGroups: {
			user_123: [
				'group_1',
				'group_2',
			],
			user_456: [
				'group_1',
				'group_2',
			],
		},

		groups_with_chats: {
			group_1: {
				groupName: 'saedhaedhf',
				createdBy: '123'
				members:{
					uniqid : {
						user_1_name: "sruhieru",
						user_1_id: "123"
					},
				}
				chats:{ 
					{
						sender_id: "",
						sender_name: "",
						text: "",
						timestamp: ""
					},
					{
						sender_id: "",
						sender_name: "",
						text: "",
						timestamp: ""
					},.....
				}
			},
			group_2: {
				groupName: 'saedhaedhf',
				createdBy: '123'
				members:{
					uniqid : {
						user_1_name: "sruhieru",
						user_1_id: "123"
					},
				}
				chats:{ 
					{
						sender_id: "",
						sender_name: "",
						text: "",
						timestamp: ""
					},
					{
						sender_id: "",
						sender_name: "",
						text: "",
						timestamp: ""
					},
				}
			}
		}
	}

	*/
}
