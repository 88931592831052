import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Task{
  project_name:String,
  task_name:String,
  description:String,
  users:String,
  finished:String
}

@Component({
  selector: 'app-admintask',
  templateUrl: './admintask.component.html',
  styleUrls: ['./admintask.component.css']
})
export class AdmintaskComponent implements OnInit {

  displayedColumns:string[] = ['id','project_name','task_name','description','users','finished','action'];
  data:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Task> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
  addTaskForm = this.formBuilder.group({
    id: new FormControl(''),
    projectid: new FormControl('',[Validators.required]),
    taskname: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    userids: new FormControl('',[Validators.required]),
    isfinished: new FormControl('',[Validators.required]),    
  });
  addtasktbtn:any;
  edittaskbtn:any;
  projectname:any;
  taskid:any;
  array:any;
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private formBuilder: FormBuilder,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { 

    // this.formGroup = this.formBuilder.group({
    //   toppings: [[], Validators.required],
    // })
  }

  ngOnInit(): void {
  
    this.addtasktbtn = true;
    this.edittaskbtn = false;
    this.ProjectNameList();
    this.UserList();
    this.TaskList(this.id,this.searchvalue,this.pageSize,this.currentPage);
  }
  changePaging(event: PageEvent)
  {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;

    this.TaskList(this.id,this.searchvalue,this.pageSize,page);
  }
  ProjectNameList()
   {
    this.http.post(Api.url.adminProject).then((response: any)=>{
      if(response.success)
      {
        this.projectname = response.data;
      }
      }).catch((error) => {
     });
   }
  TaskList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminTaskList,{projectid:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        this.dataSource.sort = this.sort;
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   onSelectEvent(event:any){
    // console.log(event);
    this.currentPage = 0;
    this.TaskList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }
   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }
   
   addTask(){
    this.http.post(Api.url.adminTaskAdd,this.addTaskForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Task Successfully","","green-snackbar");
         $("#TaskModal").modal('hide');
         this.TaskList(this.id,this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }
   deleteTask(id:any)
   {
    // console.log(id);
    this.taskid = id;
    $("#deltask").modal('show');
  
   }

   deltask(){
    this.http.post(Api.url.adminTaskDelete,{id:this.taskid}).then((response: any)=>{
      $("#deltask").modal('hide');
      this.TaskList(this.id,this.searchvalue,this.pageSize,this.currentPage);
      this.openSnackBar("Delete Task Successfully","","green-snackbar");
    });
   }

   editTask(element:any)
   {
        console.log(element);
        this.edittaskbtn = true;
        this.addtasktbtn = false;
        var index = this.usersname.findIndex(function(el: { name: string; }) {
          return el.name == element.username;
        });
        $("#TaskModal").modal('show');
        
        this.addTaskForm.controls['id'].setValue(element['id']);
        this.addTaskForm.controls['projectid'].setValue(element['projectid']);
        this.addTaskForm.controls['userids'].setValue(this.usersname[index]['id']);
        this.addTaskForm.controls['taskname'].setValue(element['taskname']);
        this.addTaskForm.controls['description'].setValue(element['description']);
        this.addTaskForm.controls['isfinished'].setValue(element['isfinished']);

   }
   saveTask(){

    this.http.post(Api.url.adminTaskEdit, this.addTaskForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Edit Task Successfully","","green-snackbar");
        $("#TaskModal").modal('hide');
        this.addTaskForm.reset();
        this.TaskList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        this.edittaskbtn = false;
        this.addtasktbtn = true;
      }
    });
   }
}
