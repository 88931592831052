<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="track">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Track  </th>
            <td mat-cell *matCellDef="let element"> {{element.track}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date  </th>
            <td mat-cell *matCellDef="let element"> {{element.date}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
    </mat-paginator>
