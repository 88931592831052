import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Machine{
  name:String,
  date:String,
  checkin:String,
  checkout:String,
  description:String,
  total_time:String
}

@Component({
  selector: 'app-adminmachine',
  templateUrl: './adminmachine.component.html',
  styleUrls: ['./adminmachine.component.css']
})
export class AdminmachineComponent implements OnInit {
  displayedColumns:string[] = ['id','name','date','checkin','checkout','description','total_time'];
  data:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Machine> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  names:any;

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void {
    this.MachineList(this.id,this.searchvalue,this.pageSize,this.currentPage);
  }
  MachineList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminDailyAttendance,{name:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        this.dataSource.sort = this.sort;
        this.names = response.data.name;
        this.dataSource.data = response.data.data.data;
        this.paginator.length = response.data.data.total;
        this.length = response.data.data.total;
     }
    }).catch((error) => {
     
    });
   }
   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    // console.log(page);
    this.MachineList(this.id,this.searchvalue,this.pageSize,this.currentPage);

   }
   onSelectEvent(event:any)
   {
    // console.log(event.target.value);
    this.MachineList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }
}
