<div class="chat-icon" (click)="showChat()">
	<!-- <button id="chat-us" class="btn btn-primary shadow p-2 fw-bold">Chat</button> -->
	<!-- <button type="button" id="chat-here" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
		[disabled]="false">
		Chat
	</button> -->
	<button type="button" id="chat-here" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">
		Chat
	</button>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title fs-5" id="exampleModalLabel">Chats</div>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
					<li class="nav-item" role="presentation">
						<button class="nav-link active" id="pills-chats-tab" data-bs-toggle="pill"
							data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
							aria-selected="true">Chat</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="pills-groups-tab" data-bs-toggle="pill"
							data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
							aria-selected="false">Group</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link" id="pills-groups-tab" data-bs-toggle="pill"
							data-bs-target="#pills-groups" type="button" role="tab" aria-controls="pills-profile"
							aria-selected="false">Your Group</button>
					</li>
				</ul>
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade show active" id="pills-home" role="tabpanel"
						aria-labelledby="pills-chats-tab">
						<div class="fw-bold fs-5">Chat Room</div>
						<div id="history" class="chat-history shadow-sm my-2 py-2 rounded">
							<div *ngFor="let chat of OneToOneUserChatData | keyvalue" class="mb">
								<div style="width: 100%; display: flex;"
									[style.justify-content]="chat.value.senderID == loginUserID ? 'end' : 'start' ">
									<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
									<div class="border border-1 p-1 shadow-sm bg-body rounded">
										<div style="max-width: 100px; word-wrap: break-word;">
											{{chat.value.text}}
										</div>
									</div>
								</div>
								<div class="d-flex" style="font-size: 10px;"
									[style.justify-content]="chat.value.senderID == loginUserID ? 'end' : 'start' ">
									{{chat.value.timestamp}}</div>
							</div>
						</div>

						<form id="message" class="chat-form" [formGroup]="form" (ngSubmit)="onChatSubmit(form.value)">
							<mat-form-field>
								<mat-label>Select an user</mat-label>
								<mat-select [(value)]="selected" formControlName="username"
									(selectionChange)="usernamesForChat($event)">
									<mat-option>None</mat-option>
									<mat-option value="{{names.id}}"
										*ngFor="let names of userList">{{names.name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<!-- <mat-form-field appearance="outline" class="fw">
								<mat-label>Write Here</mat-label>
								<input matInput formControlName="message" id="inputMessage">
								<button type="submit" matSuffix mat-icon-button aria-label="Submit">
									<mat-icon>send</mat-icon>
								</button>
							</mat-form-field> -->
						</form>
					</div>
					<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-groups-tab">
						<div class="fw-bold fs-5">Group Chat Room</div>
						<div id="history" class="chat-history shadow-sm my-2 py-2 rounded">
							<div *ngFor="let chat of groupChatData | keyvalue" class="mb">
								<div style="width: 100%; display: flex;"
									[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
									<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
									<div class="border border-1 p-1 shadow-sm bg-body rounded">
										<div style="max-width: 100px; word-wrap: break-word;">
											{{chat.value.text}}
										</div>
									</div>
								</div>
								<div class="d-flex" style="font-size: 10px;"
									[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
									{{chat.value.sender_name}}({{chat.value.timestamp}})</div>
							</div>
						</div>
						<!-- <form id="message" class="group-chat-form" [formGroup]="form" (ngSubmit)="onGroupChatSubmit()">
							<mat-form-field appearance="outline" class="fw">
								<mat-label>Write Here</mat-label>
								<input matInput formControlName="message" id="inputgroupChatMessage">
								<button type="submit" matSuffix mat-icon-button aria-label="Submit">
									<mat-icon>send</mat-icon>
								</button>
							</mat-form-field>
						</form> -->
					</div>
					<div class="tab-pane fade" id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
						<div class="fw-bold fs-4 text-center">Your Groups</div>
						<div class="login-user-group-list" *ngFor="let list of currentUserGroupList">
							<button type="button" class="btn group-names my-2 p-2 rounded shadow-sm" id="group-names" value={{list.gpId}} (click)="findGroupName($event)">
								{{list.gpName}}
							</button>
						</div>
						<div class=" my-2">
							<div class="group-Chat-histroy p-2 rounded shadow-sm" *ngIf="noGroupChatHistroyFound" [innerHTML]="groupChathistroy"></div>
								<div *ngFor="let chat of erpTestGroupChatHistroy | keyvalue" class="mb">
									<div style="width: 100%; display: flex;"
										[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
										<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
										<div class="border border-1 p-1 shadow-sm bg-body rounded">
											<div style="max-width: 100px; word-wrap: break-word;">
												{{chat.value.text}}
											</div>
										</div>
									</div>
									<div class="d-flex" style="font-size: 10px;"
										[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
										{{chat.value.sender_name}}({{chat.value.timestamp}})</div>
								</div>
							<div>
								
								<!-- <form class="user-group-chat-form" [formGroup]="form" (ngSubmit)="onGroupsChatSubmit()">
									<mat-form-field appearance="outline" class="fw">
										<mat-label>Write Here</mat-label>
										<input matInput id="groupChatMessage">
										<button type="submit" matSuffix mat-icon-button aria-label="Submit">
											<mat-icon>send</mat-icon>
										</button>
									</mat-form-field>
								</form> -->
							</div>
						</div>
						<button type="button" class="btn btn-primary" data-bs-toggle="modal"
							data-bs-target="#staticBackdrop">
							Create New Group
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal -->
	<!-- Chat box Modal -->
  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header px-3 py-2 border">
		  <div class="modal-title fs-5" id="exampleModalLabel">Chat</div>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		  	<div class="row">
				<div class="col-md-4">
					<div class="user-List">
						<div>Users</div>
						<form action="">
							<mat-form-field>
								<mat-label>Select an user</mat-label>
								<mat-select [(value)]="selected" formControlName="username"
									(selectionChange)="usernamesForChat($event)">
									<mat-option>None</mat-option>
									<mat-option value="{{names.id}}"
										*ngFor="let names of userList">{{names.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</form>
						<div>Recent chats</div>
						<div  id="userLists" class="border border-1 rounded p-1">
							<div *ngFor="let list of userRecentChatsList">
								
								<button class="btn shadow-sm p-2 my-1 rounded" id="{{list.id}}" value="{{list.name}}" (click)="usernamesForChat($event)" style="font-size: 12px; width: 100%; text-align: start;">
									<img src="/assets/images/default.png" alt="" style="height: 20px; width: 20px; object-fit: contain; border-radius: 50px;">
									{{list.name}}
								</button>
							</div>
						</div>
					</div>
					<!-- <div class="common-group">
						<div>
							<button class="btn"  (click)="commonGroup($event)">BMAC Group</button>
						</div>
					</div> -->
					<div class="mt-3">Groups</div>
					<div class="group-list border border-1 rounded p-1">
						<div>
							<button class="btn group-names my-2 p-2 rounded shadow-sm w-100" value="BMAC Group"  (click)="commonGroup($event)" style="font-size: 12px;">BMAC Group</button>
						</div>
						<div class="login-user-group-list" *ngFor="let list of currentUserGroupList">
							<button type="button" class="btn group-names my-2 p-2 rounded shadow-sm w-100" id="group-names" value={{list.gpId}} (click)="findGroupName($event)" style="font-size: 12px;">
								{{list.gpName}}
							</button>
						</div>
					</div>
					<div class="my-2 p-1">
						<button type="button" class="btn border border-1 rounded" data-bs-toggle="modal"
														data-bs-target="#staticBackdrop" 
														style="font-size: 12px; width: 100%;">
														Create Group
						</button>
					</div>
				</div>
				<div class="col-md-8">
					<div class="shadow-sm my-2 p-2 rounded d-flex align-items-center justify-content-between">
						<div class="">
							<img src="/assets/images/default.png" alt="" style="height: 30px; width: 30px; object-fit: contain; border-radius: 50px;">
							<span [innerHTML]="chatBoxTitle" class="ms-2"></span>
						</div>
						<div>
							<button class="btn border border-1 rounded" *ngIf="isGroupChat == 3 && !isGroupUsers" value="{{groupKeyForGroupUserList}}" (click)="viewGroupUsers($event)" style="font-size: 12px;">
								View Details
							</button>
							<button class="border-0 rounded" *ngIf="isGroupChat == 3 && isGroupUsers" value="{{groupKeyForGroupUserList}}" (click)="findGroupName($event)">
								&#8626;
							</button>
						</div>
					</div>
					<div class="common mt-3" style="height: 88% !important;">
						<div *ngIf="isGroupUsers">
							<div class="d-flex justify-content-between align-items-center">
								<div>Members</div>
								<div *ngIf="groupCreatorId == loginUserID">
									<button class="btn btn-primary" style="font-size: 12px;" data-bs-toggle="modal" data-bs-target="#addMembersModal" value="{{groupKeyForGroupUserList}}" (click)="addNewGroupMembers()">
										Add Members
									</button>
								</div>
							</div>
							<div class="group-members-list px-2">
								<div *ngFor="let gpUserlist of groupUsersListToshow">
									<div class="d-flex justify-content-between align-items-center shadow-sm my-1 p-1">
										<div>
											<div class="btn " style="font-size: 12px;">
												<img src="/assets/images/default.png" alt="" style="height: 20px; width: 20px; object-fit: contain; border-radius: 50px;">
												{{gpUserlist.gpMemberName}}
											</div>
											<!-- <button class="btn " style="font-size: 12px;" id="{{gpUserlist.gpMemberId}}" value="{{gpUserlist.gpMemberName}}" (click)="usernamesForChat($event)">
												<img src="/assets/images/default.png" alt="" style="height: 20px; width: 20px; object-fit: contain; border-radius: 50px;">
												{{gpUserlist.gpMemberName}}
											</button> -->
										</div>
										<!-- <div>{{groupCreatorId == loginUserID}}</div> -->
										<div *ngIf="groupCreatorId == loginUserID">
											<button class="btn border border-1" *ngIf="loginUserID != gpUserlist.gpMemberId" id="{{gpUserlist.gpMemberId}}" value="{{groupKeyForGroupUserList}}" 
												(click)="removeGroupMembers($event)"
												style="font-size: 12px;">
												Remove
											</button>
										</div>
									</div>
								</div>
							</div>
							
							<div>Details</div>
							<div *ngFor="let gpDetails of activeGroupDetails" class="shadow-sm my-2 p-2 rounded" style="font-size: 12px;">
								<div><span class="fw-bold">Created By:</span> {{gpDetails.createdBy}}</div>
								<div><span class="fw-bold">Created At:</span> {{gpDetails.createdAt}}</div>
							</div>
						</div>
						<div *ngIf="!isGroupUsers" class="h-100">
							<div class="h-100 d-flex align-items-center justify-content-center shadow-sm rounded" *ngIf="(isGroupChat != 1) && (isGroupChat != 2) && (isGroupChat != 3)">Select user Or Groups</div>
							<div *ngIf="(isGroupChat == 1) || (isGroupChat == 2) || (isGroupChat == 3)"> 
								<div id="history" class="chat-history shadow-sm my-2 py-2 rounded" #scroll>
									<div *ngIf="isGroupChat == 1">
										<div *ngFor="let chat of OneToOneUserChatData | keyvalue" class="mb">
											<div style="width: 100%; display: flex;"
												[style.justify-content]="chat.value.senderID == loginUserID ? 'end' : 'start' ">
												<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
												<div class="border border-1 p-1 shadow-sm bg-body rounded">
													<div style="max-width: 215px; word-wrap: break-word;">
														{{chat.value.text}}
													</div>
												</div>
											</div>
											<div class="d-flex" style="font-size: 10px;"
												[style.justify-content]="chat.value.senderID == loginUserID ? 'end' : 'start' ">
												{{chat.value.timestamp}}</div>
										</div>
									</div>
									<div *ngIf="isGroupChat == 2">
										<div *ngFor="let chat of groupChatData | keyvalue" class="mb">
											<div style="width: 100%; display: flex;"
												[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
												<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
												<div class="border border-1 p-1 shadow-sm bg-body rounded">
													<div style="max-width: 215px; word-wrap: break-word;">
														{{chat.value.text}}
													</div>
												</div>
											</div>
											<div class="d-flex" style="font-size: 10px;"
												[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
												{{chat.value.sender_name}}({{chat.value.timestamp}})</div>
										</div>
									</div>
									<div *ngIf="isGroupChat == 3">
										<div *ngFor="let chat of erpTestGroupChatHistroy | keyvalue" class="mb">
											<div style="width: 100%; display: flex;"
												[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
												<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.value.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
												<div class="border border-1 p-1 shadow-sm bg-body rounded">
													<div style="max-width: 215px; word-wrap: break-word;">
														{{chat.value.text}}
													</div>
												</div>
											</div>
											<div class="d-flex" style="font-size: 10px;"
												[style.justify-content]="chat.value.sender_id == loginUserID ? 'end' : 'start' ">
												{{chat.value.sender_name}}({{chat.value.timestamp}})</div>
										</div>
									</div>
								</div>
							</div>
							<div class="chat-area">
								<div *ngIf="isGroupChat == 1">
									<form id="message" class="chat-form" [formGroup]="form" (ngSubmit)="onChatSubmit(form.value)">
										<mat-form-field appearance="outline" class="fw">
											<mat-label>Write Here</mat-label>
											<input matInput formControlName="message" id="inputMessage">
											<button type="submit" matSuffix mat-icon-button aria-label="Submit">
												<mat-icon>send</mat-icon>
											</button>
										</mat-form-field>
									</form>
								</div>
								<div *ngIf="isGroupChat == 2">
									<form id="message" class="group-chat-form" [formGroup]="form" (ngSubmit)="onGroupChatSubmit()">
										<mat-form-field appearance="outline" class="fw">
											<mat-label>Write Here</mat-label>
											<input matInput formControlName="message" id="inputgroupChatMessage">
											<button type="submit" matSuffix mat-icon-button aria-label="Submit">
												<mat-icon>send</mat-icon>
											</button>
										</mat-form-field>
									</form>
								</div>
								<div *ngIf="isGroupChat == 3">
									<form class="user-group-chat-form" [formGroup]="form" (ngSubmit)="onGroupsChatSubmit()">
										<mat-form-field appearance="outline" class="fw">
											<mat-label>Write Here</mat-label>
											<input matInput id="groupChatMessage">
											<button type="submit" matSuffix mat-icon-button aria-label="Submit">
												<mat-icon>send</mat-icon>
											</button>
										</mat-form-field>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
		  	</div>
		</div>
		<div class="modal-footer px-3 py-2 border">
		  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		</div>
	  </div>
	</div>
  </div>


  
  <!-- Modal -->
  <!-- Add Member Modal -->
  <div class="modal fade" id="addMembersModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header">
		  <div class="modal-title fs-5" id="exampleModalLabel">Add Members</div>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
			<div class="">
				<img src="/assets/images/default.png" alt="" style="height: 30px; width: 30px; object-fit: contain; border-radius: 50px;">
				<span [innerHTML]="chatBoxTitle" class="ms-2"></span>
			</div>
			<div class="add-new-members">
				<form id="create-group" class="add-new-group-members-form" [formGroup]="form">
					<mat-form-field>
						<mat-label>Add Members</mat-label>
						<mat-select [(value)]="addNewUsersToGroups" (selectionChange)="addMemberTothisGroup($event)" multiple>
							<mat-option>None</mat-option>
							<mat-option *ngFor="let names of addNewMembersToGroupList" value="{{names.id}}">{{names.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="w-100 text-right" >
						<button type="submit" class="btn border border-1 rounded" aria-label="Submit" data-bs-dismiss="modal" (click)="addMembers()" style="font-size: 12px;">
							Add Members
						</button>
					</div>
				</form>
			</div>
		</div>
		<!-- <div class="modal-footer">
		  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		</div> -->
	  </div>
	</div>
  </div>


  <!-- Create Group Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
	aria-labelledby="staticBackdropLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">Create New Group</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form id="create-group" class="create-group-form" [formGroup]="form" (submit)="onCreateGroupFromSubmit()">
					<mat-form-field appearance="outline" class="fw">
						<mat-label>Gruop Name</mat-label>
						<input matInput formControlName="" id="gruopName">
					</mat-form-field>
					<mat-form-field>
						<mat-label>Select Members</mat-label>
						<mat-select [(value)]="addUsersToGroups" multiple
							(selectionChange)="groupUserList($event)">
							<mat-option>None</mat-option>
							<mat-option value="{{names.id}}"
								*ngFor="let names of userList">{{names.name}}</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="text-right">
						<button class="btn border border-1 rounded" type="submit" aria-label="Submit" data-bs-dismiss="modal" style="font-size: 12px;">
							Create Group
						</button>
					</div>
				</form>
			</div>
			<!-- <div class="modal-footer">
		  		<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		  		<button type="button" class="btn btn-primary">Understood</button>
			</div> -->
		</div>
	</div>
</div>