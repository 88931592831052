<!-- <mat-list-item [formGroup]="group">
	<mat-form-field>
		<mat-label>Select Task</mat-label>
		<mat-select class=" example-select" formControlName="{{name}}" (selectionChange)="addNewItem($event.value)">

			<mat-option *ngFor="let item of items" [value]=item.value>{{item.value}}</mat-option>
		</mat-select>
	</mat-form-field>
</mat-list-item> -->


<mat-form-field>
	<mat-label>{{label}}</mat-label>

	<input matInput #inputField [placeholder]="placeholderText" (focus)="filterItems()" (keyup)=" searchProject($event)"
		[matAutocomplete]="auto">

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="addNewItem($event)">
		<mat-option *ngFor="let item of filteredItems" [value]="item.value" [id]="item.id">
			{{item.value}}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>


<!-- <div *ngFor="let item of items">
		{{item.value}}
	</div> -->