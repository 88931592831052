import { Component, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { selectboxComponent } from '../../layouts/selectbox/selectbox.component';
import { InputboxComponent } from '../../layouts/inputbox/inputbox.component';
import { HttpService } from 'src/app/services/http.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { Api } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullComponent } from '../../layouts/full/full.component'
import { formatDate, Time, DatePipe } from '@angular/common';
import { customCalendar } from '../../shared/calendars/customcalendar';
import { TaskDialogComponent } from 'src/app/task-dialog/task-dialog.component';


declare var $: any;
export interface TodayTask {
	startime: Time,
	endtime: Time,
	projectname: string,
	taskname: string,
	description: string
}



/**
 * @title Dialog Animations
 */
@Component({
	selector: 'modal',
	styleUrls: ['modal.component.css'],
	template: `<mat-list>
  <mat-list-item >
    <h1>Add Task</h1> 
  </mat-list-item>
  <form [formGroup]="reactiveForm"  (ngSubmit)="AddTask()" novalidate>
  <div class="my-1">Project Name</div>
	<app-selectbox [items]="projectoptions" [group]="reactiveForm"  [name]="'projectname'"
		(newItemEvent)="addProjectName($event)"></app-selectbox>
  <div class="d-flex justify-content-between align-items-center">
		<div class="my-1">Task Name</div>
		<div [ngStyle]="{'color':'#23527c', 'cursor':'pointer'}" [class.disabled]="!isProjectNameSelected" (click)="openTaskDialog()" >Add new task</div>
		
  </div>

      <app-selectbox  [class.disabled]="!isProjectNameSelected" [items]="taskoptions" [group]="reactiveForm"   [name]="'taskname'" 
			(newItemEvent)="addItem($event)" ></app-selectbox>


			
  <div class="d-flex justify-content-between align-items-center">
  <div>
		<div class="d-flex justify-content-between align-items-center">
			<ngx-material-timepicker-toggle [for]="toggleTimepicker" style="float: right;"></ngx-material-timepicker-toggle>
			<input matInput placeholder="Start Time" [ngxTimepicker]="toggleTimepicker" [disableClick]="true" readonly [value]=checkintime formControlName="starttime" name="starttime">
			<ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
		</div>
		<div *ngIf="isTimeInvalidChecked" style="color: red;">
			Please select a time After check in time.
		</div>
  </div>

	<div>
		<div class="d-flex justify-content-between align-items-center">
			<ngx-material-timepicker #toggleTimepicker2  [defaultTime]="currenttime"></ngx-material-timepicker>
			<ngx-material-timepicker-toggle [for]="toggleTimepicker2" style="float: right;" ></ngx-material-timepicker-toggle>
			<input matInput placeholder="End Time" [ngxTimepicker]="toggleTimepicker2" [disableClick]="true" readonly [value]=currenttime formControlName="endtime" name="endtime">
		</div>
		<div *ngIf="isTimeInvalid" style="color: red;">
			Please select a time before the current time.
		</div>
	</div>
  </div>
  <div class="d-flex align-items-center gap-1">
	<div style="position: relative">
	
		<div *ngIf="isTimeInvalidChecked" style="color: red;">
			Please select a time After check in time.
		</div>
	</div>
	<div  style="position: relative">
	
	
	</div>
  </div>
  <div class="mt-2">
    <mat-form-field>
      <input name="id" type="hidden" [value]="userid" formControlName="userid" name="userid">
      <input matInput value="" placeholder="Description" #message formControlName="description" name="description"> 
    </mat-form-field>
  </div>
  </form>
  </mat-list>
  <mat-list-item >
  <div class="button-row text-center">
      <button mat-raised-button color="primary" [disabled] ="!reactiveForm.valid" (click)="AddTask()" >Save</button>
			<button mat-dialog-close mat-raised-button    color="" >
      Close
    </button>
  </div>
  </mat-list-item>`
})
export class ModalComponent {
	projectoptions: any;
	projectlist: any;
	projectname = 'projectname';
	projectId = 'projectId';
	taskname = 'taskname';
	taskoptions: any;
	result: any;
	userid: any;
	tasklist: any;
	checkintime: any;
	currenttoday: any;
	currenttime: any;
	starttime: any;
	isProjectNameSelected: boolean = false;

	projectIDByName: any;


	reactiveForm = new FormGroup({
		projectname: new FormControl('', [Validators.required]),
		taskname: new FormControl('', [Validators.required]),
		starttime: new FormControl(''),
		endtime: new FormControl(''),
		description: new FormControl('', [Validators.required]),
		userid: new FormControl('')
	});
	today: Date;
	today2: Date | undefined;
	currenttime2: any;
	validationStartTime: Date;
	validationEndTime: Date;
	formattedTime: string;
	formattedTimeCheckIn: string

	isTimeInvalid: boolean = false;
	isTimeInvalidChecked: boolean = false;


	openSnackBar(message: string, action: string, Color: string) {
		this.snackBar.open(message, action, {
			duration: 2000,
			verticalPosition: 'top',
			horizontalPosition: 'end',
			panelClass: [Color]
		});
	}
	constructor(public dialogRef: MatDialogRef<ModalComponent>, public dialog: MatDialog, private http: HttpService, private auth: AuthGuard, private api: Api, private fb: FormBuilder, public snackBar: MatSnackBar, private cdr: ChangeDetectorRef, private viewContainerRef: ViewContainerRef, private datePipe: DatePipe) {
		this.Project();
		this.isCheckedIn();
		this.today = new Date();
		this.currenttime = formatDate(this.today, 'hh:mm a', 'en-US', '+0530');
		console.log("curr-----------!!!!!!>", this.currenttime);


		// console.log("Currunt Time--------->", this.currenttime);
		// this.reactiveForm = this.fb.group({
		// 	endtime: [this.currenttime, Validators.required],
		// });

		this.reactiveForm.controls['endtime'].setValue(this.currenttime);
	}


	isCheckedIn() {
		this.http.post(Api.url.isCheckedIn).then((response: any) => {
			// this.today2 = new  Date ("2014-10-10 10:53");
			// console.log("CHECKING MY TIME_--------------->", response.data[0]);
			// if (response.data[0]['isCheckedIn']) {
			this.today2 = new Date(response.data[0]['checkinTime']);

			if (isNaN(this.today2.getTime())) {
				this.today2 = new Date();
			}
			this.validationStartTime = this.today2;
			console.log("asdasd-->", this.today2);
			this.currenttime2 = formatDate(this.today2, 'hh:mm a', 'en-US', '+0530');
			// this.currenttime =  formatDate((response.data[0]['checkinTime']), 'hh:mm a', 'en-US', '+0530');
			// console.log(response.data[0]['checkinTime'].slice(0, -6));
			// response.data[0]['checkinTime']
			// this.starttime = formatDate(response.data[0]['checkinTime'], 'hh:mm a', 'en-US', '+0530');
			this.reactiveForm.controls['starttime'].setValue(this.currenttime2);


			// }
			// else {

			// }

		});
	}



	openDialog(): void {
		// this.starttime = "1ff"; 

		const dialogRef = this.dialog.open(ModalComponent, {
			width: '250px',
			autoFocus: false,
			viewContainerRef: this.viewContainerRef
		});
		dialogRef.afterClosed().subscribe(result => {

		});
	}

	Project() {
		this.http.get(Api.url.project).then((response: any) => {
			if (response.success) {

				this.projectlist = response.data;
				this.projectoptions = this.projectlist.map((data: any) => {
					return {
						value: data.name,
						label: "Select Project",
						id: data.id,
					}
				});
			}
		});
	}

	TaskList(id: any) {

		

		this.result = localStorage.getItem('logindata');
		let array = JSON.parse(this.result);
		this.userid = array['user']['id'];

		this.http.get(Api.url.task, { id: id, userids: this.userid }).then((response: any) => {
			// console.log(response)
			if (response.success) {
				this.tasklist = response.data;
				// console.log(this.tasklist);
				this.taskoptions = this.tasklist.map((tyre: any) => {
					return {
						value: tyre.taskname,
						label: "Select Task",
					}
				});
				// console.log(this.projectid);
			}
		});
	}


	addTaskTimeValidationEnd() {
		const selectedTime = this.reactiveForm.get('endtime').value;
		const date = new Date();
		const formattedDateString = this.datePipe.transform(date, 'yyyy-MM-dd');
		const formattedDateTimeString = `${formattedDateString} ${selectedTime}`;
		this.formattedTime = this.datePipe.transform(formattedDateTimeString, 'HH:mm:ss');
		console.log("selectedTime STING.....--------->", this.formattedTime);


		const specificTimeParts = this.formattedTime.split(':');

		const specificTime = {
			hours: parseInt(specificTimeParts[0], 10),
			minutes: parseInt(specificTimeParts[1], 10),
			seconds: parseInt(specificTimeParts[2], 10),
		};
		const currentTime = {
			hours: new Date().getHours(),
			minutes: new Date().getMinutes(),
			seconds: new Date().getSeconds(),
		};

		this.isTimeInvalid =
			specificTime.hours > currentTime.hours ||
			(specificTime.hours === currentTime.hours && specificTime.minutes > currentTime.minutes) ||
			(specificTime.hours === currentTime.hours && specificTime.minutes === currentTime.minutes && specificTime.seconds > currentTime.seconds);

		return this.isTimeInvalid
	}

	addTaskTimeValidationStart() {
		const selectedTimeStart = this.reactiveForm.get('starttime').value
		const date = new Date();
		const formattedDateString = this.datePipe.transform(date, 'yyyy-MM-dd');
		const formattedDateTimeString = `${formattedDateString} ${selectedTimeStart}`;
		this.formattedTime = this.datePipe.transform(formattedDateTimeString, 'HH:mm:ss');
		console.log("selectedTime STING.....--------->", this.formattedTime);

		const checkInTime = this.currenttime2
		const dateChackIn = new Date();
		const formattedDateStringCheckIn = this.datePipe.transform(date, 'yyyy-MM-dd');
		const formattedDateTimeStringCheckIn = `${formattedDateStringCheckIn} ${checkInTime}`;
		this.formattedTimeCheckIn = this.datePipe.transform(formattedDateTimeStringCheckIn, 'HH:mm:ss');


		const selectedStartTime = this.formattedTime.split(':');
		const specificTime = {
			hours: parseInt(selectedStartTime[0], 10),
			minutes: parseInt(selectedStartTime[1], 10),
			seconds: parseInt(selectedStartTime[2], 10),
		};

		const checkedInFormat = this.formattedTimeCheckIn.split(':')
		const checkedInTime = {
			hours: parseInt(checkedInFormat[0], 10),
			minutes: parseInt(checkedInFormat[1], 10),
			seconds: parseInt(checkedInFormat[2], 10),
		};


		this.isTimeInvalidChecked = specificTime.hours < checkedInTime.hours ||
			(specificTime.hours === checkedInTime.hours && specificTime.minutes < checkedInTime.minutes) ||
			(specificTime.hours === checkedInTime.hours && specificTime.minutes === checkedInTime.minutes && specificTime.seconds < checkedInTime.seconds);



		return this.isTimeInvalidChecked;

	}




	AddTask() {

		// console.log("START------- -----------!!!!!!>", this.currenttime2);

		// const selectedTimeStart = this.reactiveForm.get('starttime').value
		// const date = new Date();
		// const formattedDateString = this.datePipe.transform(date, 'yyyy-MM-dd');
		// const formattedDateTimeString = `${formattedDateString} ${selectedTimeStart}`;
		// this.formattedTime = this.datePipe.transform(formattedDateTimeString, 'HH:mm:ss');
		// console.log("selectedTime STING.....--------->", this.formattedTime);



		// const checkInTime = this.currenttime2
		// const dateChackIn = new Date();
		// const formattedDateStringCheckIn = this.datePipe.transform(date, 'yyyy-MM-dd');
		// const formattedDateTimeStringCheckIn = `${formattedDateStringCheckIn} ${checkInTime}`;
		// this.formattedTimeCheckIn = this.datePipe.transform(formattedDateTimeStringCheckIn, 'HH:mm:ss');

		// console.log("selectedTime STING.... CHECKIN TIMEEEE.--------->", this.formattedTimeCheckIn);

		// console.log(this.addTaskTimeValidationStart());
		// console.log(this.addTaskTimeValidationEnd());


		if (this.addTaskTimeValidationEnd() || this.addTaskTimeValidationStart()) {
			return
		}

		else {

			// this.reactiveForm.push('id',25);
			this.http.post(Api.url.addtask, this.reactiveForm.value).then((response: any) => {
				if (response.success) {
					this.openSnackBar("Add Task Successfully", "", "blue-snackbar");
					this.dialogRef.close();
					// const end = 1675555200;
					// const start = 1672531200;
					// const self = '';
					// this.getData(start,end,self);
					customCalendar().init(this);
				}
			});
		}
	}
	getData(start: any, end: any, self: any) {
		return new Promise(resolve => {
			this.http.get(Api.url.caltask, { start: start.unix(), end: end.unix(), })
				.then((doc: any) => {
					resolve(doc);
				});
		})

	}
	addItem(newItem: string) {
		this.reactiveForm.controls['taskname'].setValue(newItem);
	}
	addProjectName(newItem: string) {

		console.log("CHECKING NEW ITEM PROJEXTZZZZZZZZ----->", newItem);

		// console.log("NEW--->", this.reactiveForm.get('taskname').value);
		this.reactiveForm.controls['projectname'].setValue(newItem);
		
		this.projectIDByName = newItem;
		this.isProjectNameSelected = true;
		
		const getProjectName = this.projectlist.find(ele => ele.name == this.projectIDByName);
		
		const getProjectId = getProjectName.id;
		this.TaskList(getProjectId);
		// console.log("p id", getProjectId)
		// console.log(this.reactiveForm.get('taskname').value);
		this.reactiveForm.get('taskname').setValue('');
		this.cdr.detectChanges();
		// console.log("My PRoject ID ------->", this.projectIDByName);

	}

	openTaskDialog(): void {

		const getProjectName = this.projectlist.find(ele => ele.name == this.projectIDByName);

		const getProjectId = getProjectName.id;
		console.log("CHECK PROJECT ID---------->", getProjectName);
		console.log("CHECK PROJECT ID---------- IIDDD>", getProjectId);
		const dialogRef = this.dialog.open(TaskDialogComponent, {
			width: '400px',
			data: { key: getProjectId }
		}).afterClosed().subscribe(sub => {

			this.TaskList(getProjectId);
			// this.TaskList(getProjectName.name);
		})
	}

	selctProject(e: any){
		console.log("changed", e)
	}
}

