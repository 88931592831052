
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import {MatSelectModule} from '@angular/material/select';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';


import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './user/profile/profile.component';
import { AuthGuard } from './services/auth.guard';
import { AttendanceComponent } from './user/attendance/attendance.component';
import { LeaveComponent } from './user/leave/leave.component';
import { TimesheetComponent } from './user/timesheet/timesheet.component';
import { HolidayComponent } from './user/holiday/holiday.component';
import { TaskTrackComponent } from './user/task-track/task-track.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialComponentsModule } from '../app/material-component/material.module';
import { LeavemodalComponent } from './layouts/leavemodal/leavemodal.component';
import { AdminloginComponent } from './admin/adminlogin/adminlogin.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { UpComingEventsComponent } from './admin/dashboard/dashboard-components/up-coming-events/up-coming-events.component';
import { AbsentEmployeeTodayComponent } from './admin/dashboard/dashboard-components/absent-employee-today/absent-employee-today.component';
import { RecentActivityComponent } from './admin/dashboard/dashboard-components/recent-activity/recent-activity.component';
import { UsersComponent } from './admin/users/users.component';
import { ResumeComponent } from './admin/resume/resume.component';
import { AdminleaveComponent } from './admin/adminleave/adminleave.component';
import { AdminattendanceComponent } from './admin/adminattendance/adminattendance.component';
import { AdminprojectComponent } from './admin/adminproject/adminproject.component';
import { AdmintaskComponent } from './admin/admintask/admintask.component';
import { AdminmachineComponent } from './admin/adminmachine/adminmachine.component';
import { AdmintimesheetComponent } from './admin/admintimesheet/admintimesheet.component';
import { AdminholidayComponent } from './admin/adminholiday/adminholiday.component';
import { AdminreportComponent } from './admin/adminreport/adminreport.component';
import { AdmintasklogComponent } from './admin/admintasklog/admintasklog.component';
import { AdmininventoryComponent } from './admin/admininventory/admininventory.component';
import { AdmindocumentComponent } from './admin/admindocument/admindocument.component';
import { AdminfeedbackComponent } from './admin/adminfeedback/adminfeedback.component';
import { UsermodalComponent } from './layouts/usermodal/usermodal.component';
import { ConfirmationDialogComponent } from './layouts/confirmation-dialog/confirmation-dialog.component';


import { environment } from '../environments/environment';
import { FirebaseChatComponent } from './firestore-chat/firestore-chat.component';
import { selectboxComponent } from './layouts/selectbox/selectbox.component';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { ModalComponent } from './layouts/modal/modal.component';






@NgModule({
	declarations: [
		AppComponent,
		FullComponent,
		AppHeaderComponent,
		SpinnerComponent,
		AppSidebarComponent,
		LoginComponent,
		ProfileComponent,
		AttendanceComponent,
		LeaveComponent,
		TimesheetComponent,
		HolidayComponent,
		TaskTrackComponent,
		LeavemodalComponent,
		AdminloginComponent,
		AdminDashboardComponent,
		UpComingEventsComponent,
		AbsentEmployeeTodayComponent,
		RecentActivityComponent,
		UsersComponent,
		ResumeComponent,
		AdminleaveComponent,
		AdminattendanceComponent,
		AdminprojectComponent,
		AdmintaskComponent,
		AdminmachineComponent,
		AdmintimesheetComponent,
		AdminholidayComponent,
		AdminreportComponent,
		AdmintasklogComponent,
		AdmininventoryComponent,
		AdmindocumentComponent,
		AdminfeedbackComponent,
		UsermodalComponent,
		ConfirmationDialogComponent,
		FirebaseChatComponent,
		selectboxComponent,
		TaskDialogComponent,
		ModalComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		DemoMaterialModule,
		MaterialComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		HttpClientModule,
		SharedModule,
		MatDialogModule,
		MatSelectModule,
		RouterModule.forRoot(AppRoutes)

	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy,
		},
		AuthGuard,
		DatePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
