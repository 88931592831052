import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Attendance{
  name:String,
  checkin:String,
  checkout:String,
  attend:String,
}

@Component({
  selector: 'app-adminattendance',
  templateUrl: './adminattendance.component.html',
  styleUrls: ['./adminattendance.component.css']
})

export class AdminattendanceComponent implements OnInit {
  displayedColumns:string[] = ['id','name','checkin','checkout','attend','action'];

  data:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Attendance> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
  editAttendanceForm = new FormGroup({
    attendanceid: new FormControl(''),
    date: new FormControl('',[Validators.required]),
    checkin:new FormControl('',[Validators.required]),
    checkout:new FormControl('',[Validators.required]),
  });
  currenttime:any;
  checkout:any;
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void {
    this.UserList();
    this.AttendanceList(this.id,this.searchvalue,this.pageSize,this.currentPage);
  }

  AttendanceList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminAttendanceList,{userid:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success){
      // console.log(response.data.users);
      // this.usersname = response.data.users;
      this.dataSource.data = response.data.data;
      this.dataSource.sort = this.sort;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

  onSelectEvent(event:any)
  {
    this.id = event.target.value;
    this.AttendanceList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }

   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data)
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }

   SearchAttendance(event:any)
   {

    this.searchvalue = event.target.value;
    this.currentPage = 1;
    this.AttendanceList(this.id,this.searchvalue,this.pageSize,this.currentPage);

   }

   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.AttendanceList(this.id,this.searchvalue,this.pageSize,page);
   }

   editAttendance(element:any){
    $("#attendanceModal").modal('show');

        console.log(element);
        let join_Array =  element['createdate'].split("-");
        const joindate = new Date(join_Array[2], join_Array[1], join_Array[0]);
        this.editAttendanceForm.controls['date'].setValue(joindate);

        const today2 = new  Date (element['created_at']);
        this.currenttime  =  formatDate(today2, 'hh:mm a', 'en-US', '+0530');
       
        this.editAttendanceForm.controls['checkin'].setValue(this.currenttime);
        const today = element['checkout'].split(":");
        this.checkout = today[0]+":"+today[1];
        this.editAttendanceForm.controls['checkout'].setValue(this.checkout);
        this.editAttendanceForm.controls['attendanceid'].setValue(element['id']);

        
   }

   saveAtetndance(){
    this.http.post(Api.url.adminEditAttendance, this.editAttendanceForm.value).then((response: any) => {
      if (response.success) 
      {
         this.openSnackBar("Update Successfully","","green-snackbar");
          $("#attendanceModal").modal('hide');
          this.AttendanceList(this.id,this.searchvalue,this.pageSize,this.currentPage);
            //  this.UserList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

}
