import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Project{
  name:String,
  description:String,
  startdate:String,
  users:String,
  finished:String
}
@Component({
  selector: 'app-adminproject',
  templateUrl: './adminproject.component.html',
  styleUrls: ['./adminproject.component.css']
})
export class AdminprojectComponent implements OnInit {
  displayedColumns:string[] = ['id','name','description','startdate','users','finished','action'];

  data:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource: MatTableDataSource<Project> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
  addProjectForm = new FormGroup({
    project_id: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    startdate: new FormControl('',[Validators.required]),
    users: new FormControl('',[Validators.required]),
    finished: new FormControl('',[Validators.required]),
  });
  addprojectbtn:any;
  editprojectbtn:any;
  projectname:any;
  projectid:any;
  title_section:string;

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void 
  {
    this.title_section = "Add Project";
    this.addprojectbtn = true;
    this.editprojectbtn = false;
    this.ProjectNameList();
    this.UserList();
    this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);

  }
  changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    // console.log(page);
    this.ProjectList(this.id,this.searchvalue,this.pageSize,page);
   }
  ProjectList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminProjectList,{projectid:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        this.dataSource.sort = this.sort;
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   ProjectNameList()
   {
    this.http.post(Api.url.adminProject).then((response: any)=>{
      // console.log(response.data)
      if(response.success)
      {
        this.projectname = response.data;
      }
      }).catch((error) => {
     });
   }

   SearchProject(event:any){
    // console.log(event);
    this.ProjectList(this.id,event,this.pageSize,this.currentPage);
   }

   onSelectEvent(event:any){
    // console.log(event);
    this.id = event.target.value;
    this.ProjectList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }

   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }

   addProject(){
    this.http.post(Api.url.adminProjectAdd, this.addProjectForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Project Successfully","","green-snackbar");
         $("#ProjectModal").modal('hide');
         this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

   editProject(element:any)
   {
        this.title_section = "Update Project";
        this.editprojectbtn = true;
        this.addprojectbtn = false;
        var index = this.usersname.findIndex(function(el: { name: string; }) {
          return el.name == element.username;
        });
        
        $("#ProjectModal").modal('show');
        this.addprojectbtn = false;
        this.editprojectbtn = true;
        this.addProjectForm.controls['project_id'].setValue(element['id']);
        this.addProjectForm.controls['name'].setValue(element['name']);
        this.addProjectForm.controls['description'].setValue(element['description']);
        this.addProjectForm.controls['users'].setValue(this.usersname[index]['id']);

        let Array =  element['startdate'].split("-");
        const date = new Date(Array[2], Array[1], Array[0]);
        this.addProjectForm.controls['startdate'].setValue(date);
        this.addProjectForm.controls['finished'].setValue(element['isfinished']);
   }

   saveProject()
   {
      this.http.post(Api.url.adminProjectUpdate, this.addProjectForm.value).then((response: any) => {
        if (response.success) 
        {
          this.openSnackBar("Edit Project  Successfully","","green-snackbar");
          $("#ProjectModal").modal('hide');
          this.addProjectForm.reset();
          this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
          this.editprojectbtn = false;
          this.addprojectbtn = true;
          this.title_section = "Add Project";
        }
      });
    }

    deleteProject(id:any){
      this.projectid = id;
      $("#delproject").modal('show');
    }

    deleteproj(){
      this.http.post(Api.url.adminProjectDelete,{projectid:this.projectid}).then((response: any)=>{
        $("#delproject").modal('hide');
        this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        this.openSnackBar("Delete Project Successfully","","green-snackbar");
      });
  
    }

}


