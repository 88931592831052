import { Time } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { TimesheetmodalComponent } from '../../layouts/timesheetmodal/timesheetmodal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';

declare var $: any;
declare var moment;
export interface Timesheet {
	timedate: Date,
	startime: Time,
	endtime: Time,
	projname: String,
	taskname: String,
	description: string,
}

const ELEMENT_DATA: Timesheet[] = [];
@Component({
	selector: 'app-timesheet',
	templateUrl: './timesheet.component.html',
	styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {
	displayedColumns: string[] = ['timedate', 'startime', 'endtime', 'projname', 'taskname', 'description', 'action'];
	data: any;
	// dataSource = new MatTableDataSource<Timesheet>();

	totalRows = 0;
	pageSize = 10;
	currentPage = 0;
	pageSizeOptions: number[] = [5, 10, 25, 100];
	dataSource: MatTableDataSource<Timesheet> = new MatTableDataSource();
	@ViewChild(MatSort)
	sort!: MatSort;
	@ViewChild(MatPaginator)
	paginator!: MatPaginator;
	length: any;
	searchvalue: String = "";


	timesheetForm = new FormGroup({
		startime: new FormControl(''),
		endtime: new FormControl(''),
		description: new FormControl(''),
		projectname: new FormControl(''),
		taskname: new FormControl(''),
		userid: new FormControl(''),
		id: new FormControl(''),
	});
	openSnackBar(message: string, action: string, Color: string) {
		this.snackBar.open(message, action, {
			duration: 2000,
			verticalPosition: 'top',
			horizontalPosition: 'end',
			panelClass: [Color]
		});
	}
	constructor(public snackBar: MatSnackBar, private http: HttpService, private auth: AuthGuard, private api: Api, public dialog: MatDialog) {

	}
	ngAfterViewInit() {
		this.sort.sort(({ id: 'id', start: 'desc' }) as MatSortable);
		this.dataSource.sort = this.sort;
	}
	ngOnInit(): void {
		this.TimesheetList(this.searchvalue, this.pageSize, this.currentPage);
	}

	TimesheetList(searchvalue: String, pageSize: any, currentPage: any) {
	
		this.http.get(Api.url.timesheetList, { searchtext: searchvalue, page: currentPage, recordPerPage: pageSize }).then((response: any) => {
			if (response.success) {
				this.dataSource.data = response.data.data;
				this.paginator.length = response.data.total;
				this.length = response.data.total;
			}
		}).catch((error) => {

		});
	}
	changePaging(event: PageEvent) {
		console.log(event);
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
		const page = event.pageIndex +1;
		this.TimesheetList(this.searchvalue, this.pageSize, page);
	}

	saveTask() {
		// console.log(this.timesheetForm.value);
		this.http.post(Api.url.edittimesheet, this.timesheetForm.value).then((response: any) => {
			// console.log(response);
			if (response.success) {
				this.openSnackBar("Update Timesheet Successfully", "", "green-snackbar");
				$("#timesheetModal").modal('hide');
				this.TimesheetList(this.searchvalue, this.pageSize, this.currentPage);
			}
		});
	}

	timeSheet(event: any) {
		this.searchvalue = event.target.value;
		this.TimesheetList(this.searchvalue, this.pageSize, this.currentPage);
	}

	getTimeSheetById(element: any) {
		let current_Date = moment().format('DD-MM-YYYY');

		console.log("CURRUNT DATE-------->", current_Date);
		console.log("CURRUNT DATE CHECK-------->", element.timedate);
		//  console.log(element);
		if (current_Date == element.timedate) {
			$("#timesheetModal").modal('show');
			this.timesheetForm.patchValue({
				id: element.id,
				startime: element.startime,
				endtime: element.endtime,
				description: element.description,
				projectname: element.projname,
				taskname: element.taskname,
				userid: element.userid,
			});
		} else {
			this.openSnackBar("You can edit only today's data", "", "blue-snackbar");
		}
		// console.log(id);

		// this.http.get(Api.url.geTimesheetById,{id:id,date:date}).then((response: any)=>{
		//   if(response.success){
		//     const dialogRef = this.dialog.open(TimesheetmodalComponent, {
		//       width: '700px',
		//       data:
		//       {
		//         startime: response.data[0]['startime'],
		//         endtime:response.data[0]['startime'],
		//         description :response.data[0]['description'],
		//         projectname:response.data[0]['projname'],
		//         taskname:response.data[0]['taskname'],
		//         userid:response.data[0]['userid'],
		//         timesheetid:id,
		//       }
		//     });

		//    console.log(response.data[0]);
		//   }
		//   else{
		//     this.openSnackBar(response.message,"","redNoMatch");
		//   }
		//  }).catch((error) => {

		//  });
	}

}




