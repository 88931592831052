import { Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { HttpService } from 'src/app/services/http.service';
import { Api } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	AbstractControl,
	FormBuilder,
	Validators
} from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

// import Validation from './utils/validation';

// export class AdminleaveEditDialogComponent {
//   constructor(
//     public dialogRef: MatDialogRef<AdminleaveEditDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) { }
// }
declare var $;
declare var toastr;
declare var moment;
export interface employeeData {
	username: string;
	leavedate: string;
	leavereason: string;
	leavetype: string;
	admin_note: string;
	isapprove: string;
}


@Component({
	selector: 'app-adminleave',
	templateUrl: './adminleave.component.html',
	styleUrls: ['./adminleave.component.css'],

})


export class AdminleaveComponent implements OnInit {

	minDate = new Date(); // For disabling Previous Dates from current Date//

	// requestForm: FormGroup | any;
	isSubmitted = false;

	public CLOSE_ON_SELECTED = false;
	public init = new Date();
	public resetModal = new Date();
	public model: any = [];


	@Component({
		selector: 'app-adminleave-delete-dialog',
		templateUrl: './adminleave-delete.component.html'
	})

	@ViewChild('picker', { static: true })
	_picker!: MatDatepicker<Date>;

	constructor(private _liveAnnouncer: LiveAnnouncer, public formBuilder: FormBuilder, protected http: HttpService, public dialog: MatDialog, private changeDetect: ChangeDetectorRef, private NgZone: NgZone) { }



	public dateClass = (date: Date) => {
		if (this._findDate(date) != -1) {
			return ['selected'];
		}
		return [];
	}

	public dateChanged(event: MatDatepickerInputEvent<Date>): void {
		if (event.value) {
			const date = event.value;
			const index = this._findDate(date);

			if (index === -1) {
				this.model.push(date);
			} else {
				this.model.splice(index, 1);
			}
			this.resetModal = new Date(0);
			if (!this.CLOSE_ON_SELECTED) {
				const closeFn = this._picker.close;
				this._picker.close = () => { };
				this._picker['_componentRef'].instance._calendar.monthView._createWeekCells()
				setTimeout(() => {
					this._picker.close = closeFn;
				});
			}
		}

		// console.log(this.model);

	}
	public remove(date: Date): void {
		const index = this._findDate(date);
		this.model.splice(index, 1)
		!this.model.length && this.requestForm.controls['selectDate'].setValue('');

	}
	private _findDate(date: Date): number {
		return this.model.map((m: any) => +m).indexOf(+date);
	}

	@ViewChild(MatSort) sort: MatSort | any;
	@ViewChild(MatPaginator) paginator: MatPaginator | any;
	displayedColumns: string[] = ['username', 'leavedate', 'leavereason', 'leavetype', 'admin_note', 'isapprove', 'action'];



	dataSource: any = [];
	userEditData: any = [];
	userDataDisplay: any = [];
	deleteUserId: any;
	specificUserId: any;
	searchUserData: any;

	// VALIDATION CODE

	requestForm = this.formBuilder.group({
		addUserLeave: ['', [Validators.required]],
		selectDate: ['', [Validators.required]],
		adminNote: ['', [Validators.required]],
		leaveType: ['', [Validators.required]]

	});
	editForm = this.formBuilder.group({
		adminNote: [''],
		approvalSelect: ['', [Validators.required]],
		userId: [0, [Validators.required]]

	})

	get formElement(): { [key: string]: AbstractControl } {
		return this.requestForm.controls;
	}

	get editElement(): { [key: string]: AbstractControl } {
		return this.editForm.controls;
	}

	// get editElement(): { [key: string]: AbstractControl } {
	//   return this.editForm.controls;
	// }

	ngOnInit(): void {
		// console.log(this.requestForm.value);
		this.displayUserListByDropDown();
		this.getLeaveList({});

		$('#editModal').on('hidden.bs.modal', () => {

			this.editForm.reset();
			this.isSubmitted = false;
		});
		$('#addRequest').on('hidden.bs.modal', () => {
			this.NgZone.run(() => {
				this.requestForm.patchValue({
					addUserLeave: '',
					selectDate: '',
					adminNote: '',
					leaveType: '',
				})

				this.isSubmitted = false;
				this.model = [];
			})
			// this.changeDetect.detectChanges();
		});

	}

	private getLeaveList(data: any) {
		// console.log(data);
		this.http.post(Api.url.adminleaveList, data).then((data: any) => {
			this.dataSource = new MatTableDataSource(data.data.data);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
	}
	// ngAfterViewInit() {
	//   this.dataSource.sort = this.sort;
	//   this.dataSource.paginator = this.paginator;
	// }

	announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction} ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	searchData(data: any) {
		this.searchUserData = data.target.value;
		this.getLeaveList({ search: this.searchUserData, id: this.specificUserId })
	}

	leaveReason(data: any) {

	}
	getSpecificUser(element: any) {
		this.specificUserId = element.target.value;
		this.getLeaveList({ id: this.specificUserId, search: this.searchUserData });
	}

	displayUserListByDropDown() {
		this.http.post(Api.url.adminUserList, {}).then((data: any) => {
			let userDataList = data.data;
			userDataList.forEach((element: any) => {
				this.userDataDisplay.push(element)
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;
			});
		});
	}

	editData(data: any) {
		// this.editForm.controls['userId'].setValue(data.id);
		this.editForm.patchValue({
			userId: data.id,
			adminNote: data.admin_note,
			approvalSelect: data.isapprove
		})
		this.userEditData = data;
	}

	deleteData(data: any) {
		this.deleteUserId = data;
	}

	deleteUserRecord() {
		let id = this.deleteUserId.id
		this.http.post(Api.url.adminDeleteLeave, { id }).then((data: any) => { })
		this.getLeaveList({ id: this.specificUserId, search: this.searchUserData });

		setTimeout(() => {
			toastr.success('Record Deleted Successfully');
		}, 1000)

	}
	editLeaveSubmit() {
		this.isSubmitted = true;
		if (!this.editForm.valid) {
			return false
		}
		else {
			const adminNote = this.editForm.value.adminNote;
			const approveSelect = this.editForm.value.approvalSelect;
			const userId = this.editForm.value.userId;

			this.http.post(Api.url.adminEditLeave, { admin_note: adminNote, leaveid: userId, isapprove: approveSelect }).then((data: any) => {
				this.isSubmitted = false;
				if (data.success) {
					this.getLeaveList({ id: this.specificUserId, search: this.searchUserData })
					$('#editModal').modal('hide');
					setTimeout(() => {
						toastr.success('Updated Successfully');
					}, 1000)
				}
				else {
					toastr.error(data.message)
				}
			});
		}

	}

	dropDownValue(event: any) {

		const pageSize = event.pageSize

		this.getLeaveList({ recordPerPage: pageSize, id: this.specificUserId, search: this.searchUserData })

		// this.http.post(Api.url.adminleaveList, { recordPerPage: pageSize }).then((data: any) => {
		//   console.log(data.data.data);
		// });


	}

	onSubmit() {
		this.isSubmitted = true;
		if (!this.requestForm.valid) {
			return false;
		}
		else {
			if (!this.model.length) {
				this.requestForm.controls['selectDate'].setValue('');
				return;
			}
			else {
				const id = this.requestForm.value.addUserLeave;
				let selectDate = this.requestForm.value.selectDate;
				const leavereason = this.requestForm.value.adminNote;
				const leavetype = parseInt(this.requestForm.value.leaveType);
				const today = moment();

				let parseDate = this.model.map((ele: any) => moment(ele).format('YYYY-MM-DD'));
				console.log("LEAVE DATESZ________________------->", { leavedate: parseDate });
				console.log("LEAVE TYPEEEE=-------------------------->", leavetype);
				this.http.post(Api.url.adminAddLeave, { id, leavereason, leavetype, leavedate: parseDate }).then((data: any) => {
					if (data.success) {
						this.getLeaveList({ search: this.searchUserData, id: this.specificUserId })

						$('#addRequest').modal('hide');
						setTimeout(() => {
							toastr.success(data.message);
						}, 1000)

					} else {
						toastr.error(data.message);
					}
				});

			}

		}

	}
}
