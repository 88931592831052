import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard';
import { AttendanceComponent } from './user/attendance/attendance.component';
import { HolidayComponent } from './user/holiday/holiday.component';
import { LeaveComponent } from './user/leave/leave.component';
import { ProfileComponent } from './user/profile/profile.component';
import { TaskTrackComponent } from './user/task-track/task-track.component';
import { TimesheetComponent } from './user/timesheet/timesheet.component';
import { AdminloginComponent } from './admin/adminlogin/adminlogin.component';
import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
// import { UpComingEventsComponent } from './admin/dashboard/dashboard-components/up-coming-events/up-coming-events.component';

import { UsersComponent } from './admin/users/users.component'
import { ResumeComponent } from './admin/resume/resume.component';
import { AdminleaveComponent } from './admin/adminleave/adminleave.component';
import { AdminattendanceComponent } from './admin/adminattendance/adminattendance.component';
import { AdminprojectComponent } from './admin/adminproject/adminproject.component';
import { AdmintaskComponent } from './admin/admintask/admintask.component';
import { AdminmachineComponent } from './admin/adminmachine/adminmachine.component';
import { AdmintimesheetComponent } from './admin/admintimesheet/admintimesheet.component';
import { AdminreportComponent } from './admin/adminreport/adminreport.component';
import { AdminholidayComponent } from './admin/adminholiday/adminholiday.component';
import { AdmintasklogComponent } from './admin/admintasklog/admintasklog.component';
import { AdmininventoryComponent } from './admin/admininventory/admininventory.component';
import { AdmindocumentComponent } from './admin/admindocument/admindocument.component';
import { AdminfeedbackComponent } from './admin/adminfeedback/adminfeedback.component';
// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';

export const AppRoutes: Routes = [
	{
		path: '',
		component: FullComponent,
		children: [
			{
				path: '',
				redirectTo: '/dashboard',
				pathMatch: 'full',
			},
			{ path: 'profile', component: ProfileComponent, data: { title: 'PROFILE' } },
			{ path: 'attendance', component: AttendanceComponent, data: { title: 'ATTENDANCE' } },
			{ path: 'leave', component: LeaveComponent, data: { title: 'LEAVE' } },
			{ path: 'timesheet', component: TimesheetComponent, data: { title: 'TIMESHEET' } },
			{ path: 'holiday', component: HolidayComponent, data: { title: 'HOLIDAY' } },
			{ path: 'task_track', component: TaskTrackComponent, data: { title: 'TASK LOG' } },
			{
				path: '',
				loadChildren:
					() => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
			},
			{
				path: 'dashboard',
				data: { title: 'DASHBOARD' },
				loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
			},

		],
		canActivate: [AuthGuard],
		data: {
			role: 'User'
		}
	},
	{ path: 'login', component: LoginComponent },

	{ path: '', redirectTo: 'admin/login', pathMatch: 'full' },
	{ path: 'admin/login', pathMatch: 'full', component: AdminloginComponent },
	{
		path: 'admin', component: FullComponent, children: [
			{ path: 'home', pathMatch: 'full', component: AdminDashboardComponent, data: { title: 'DASHBOARD' } },
			{
				path: 'users', component: UsersComponent, data: { title: 'Users List' }
			},
			{
				path: 'resume', component: ResumeComponent, data: { title: 'Resume List' }
			},
			{
				path: 'leave', component: AdminleaveComponent, data: { title: 'Leave List' }
			},
			{
				path: 'attendance', component: AdminattendanceComponent, data: { title: 'Attendance List' }
			},
			{
				path: 'project', component: AdminprojectComponent, data: { title: 'Project List' }
			},
			{
				path: 'task', component: AdmintaskComponent, data: { title: 'Task List' }
			},
			{
				path: 'machine', component: AdminmachineComponent, data: { title: 'Machine List' }
			},
			{
				path: 'timesheet', component: AdmintimesheetComponent, data: { title: 'Timesheet List' }
			},
			{
				path: 'holiday', component: AdminholidayComponent, data: { title: 'Holiday List' }
			},
			{
				path: 'report', component: AdminreportComponent, data: { title: 'Report List' }
			},
			{
				path: 'tasklog', component: AdmintasklogComponent, data: { title: 'Task Log List' }
			},
			{
				path: 'inventory', component: AdmininventoryComponent, data: { title: 'Invertory List' }
			},
			{
				path: 'document', component: AdmindocumentComponent, data: { title: 'Document List' }
			},
			{
				path: 'feedback', component: AdminfeedbackComponent, data: { title: 'Feedback List' }
			},
		],
		canActivate: [AuthGuard],
		data: {
			role: 'Admin'
		}
	},


	// { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard] },
];

