<div class="mat-elevation-z8">
  <div fxLayout="row ">
      <div fxFlex.gt-sm="100%" class="section width-100">
      <button mat-raised-button color="primary" class="user-btn width-100 holiday-btn" data-bs-toggle="modal" data-bs-target="#HolidayModal">Add Holiday</button>
      </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.h_date}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon class="example-icon edit-icon" (click)="editHoliday(element)">edit</mat-icon>
                <mat-icon class="example-icon delete-icon" (click)="deleteHoliday(element.id)">delete</mat-icon>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
  </mat-paginator>
</div>
<div class="modal fade" id="delholiday" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Holiday</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="delethol()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
</div>


<div class="modal hide" id="HolidayModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">{{title_section}}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <form [formGroup]="addHolidayForm" name="addholiday" style="margin-top:-45px;" id="myform">
                <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id" style="visibility:hidden;" >
                <mat-form-field>
                  <mat-label> Holiday Title </mat-label>
                  <input matInput value="" placeholder="Enter Name" #message formControlName="title" name="title">
                </mat-form-field>
            
                <span class="text-danger"
                   *ngIf="(addHolidayForm.get('title')?.invalid && addHolidayForm.get('title')?.touched && addHolidayForm.get('title')?.errors?.required )">
                       Title is required
                </span>

                <mat-form-field>
                    <mat-label> Holiday Description </mat-label>
                    <input matInput value="" placeholder="Enter Name" #message formControlName="description" name="description">
                </mat-form-field>
              
                <span class="text-danger"
                     *ngIf="(addHolidayForm.get('description')?.invalid && addHolidayForm.get('description')?.touched && addHolidayForm.get('description')?.errors?.required )">
                      Description is required
                </span>
                <!-- <mat-form-field (click)="picker.open()">
                  <mat-chip-list>
                      <mat-chip *ngFor="let value of model" [selectable]="false" [removable]="true" (removed)="remove(value)">
                          {{ value | date }}
                          <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                      </mat-chip>
                      <input matInput value="resetModal" formControlName="selectDate" matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="dateChanged($event)" hidden />

                  </mat-chip-list>
                  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker [startAt]="init" [dateClass]="dateClass"></mat-datepicker>
              </mat-form-field> -->
              <mat-form-field>
                <mat-chip-list #chipList aria-label="Choose a date" (click)="picker.open()">
                  <mat-chip
                    *ngFor="let value of model"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(value)"
                  >
                    {{ value | date }}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                  <input
                    value="resetModel"
                    matInput
                    [matDatepicker]="picker"
                    (dateChange)="dateChanged($event)"
                    [matChipInputFor]="chipList"
                    hidden
                    formControlName="hdate" name="hdate"
                  />
                </mat-chip-list>
                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker
                  #picker
                  [startAt]="init"
                  [dateClass]="dateClass"
                ></mat-datepicker>
              </mat-form-field>
                <!-- <mat-form-field  class="example-full-width" appearance="fill" (click)="picker2.open()">
                    <mat-label>Holiday Date</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="hdate" name="hdate">
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field> -->

                <span class="text-danger"
                    *ngIf="(addHolidayForm.get('hdate')?.invalid && addHolidayForm.get('hdate')?.touched && addHolidayForm.get('hdate')?.errors?.required )">
                     holiday date is required
                </span>

                <div mat-dialog-actions style="float: right;">
                    <button type="submit" *ngIf="addholidaybtn" [disabled]="addHolidayForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addHoliday()">Save</button>
                    <span class="margin-right-20"></span>
                    <button type="submit" *ngIf="editholidaybtn" mat-raised-button color="primary" tabindex="2" (click)="saveHoliday()">Update</button>
                    <span class="margin-right-20"></span>
                    <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
